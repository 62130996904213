import { ApiException, IErrorDto } from "@capetec/companies-api-client";
import axios from "axios";
import { QueryReturnValue } from "../types";

export const unknownErrorStatusCode = -1;

export const handleApiErrorWithNotFoundHandling = (
  error: unknown,
): QueryReturnValue<unknown> => {
  if (ApiException.isApiException(error) && error.status === 404) {
    return {
      data: undefined,
    };
  }

  if (axios.isAxiosError(error) && error.status === 404) {
    return {
      data: undefined,
    };
  }

  return {
    error: handleApiError(error),
  };
};

export const handleApiError = (error: unknown): IErrorDto[] => {
  console.error(error);
  if (ApiException.isApiException(error)) {
    return [
      {
        status: error.status,
        title: error.response, // TODO wait till response is object as it is returned
        detail: error.message,
      },
    ];
  }

  if (axios.isAxiosError(error)) {
    return [
      {
        status: error.status ?? unknownErrorStatusCode,
        title: error.message,
        detail: error.response?.statusText,
      },
    ];
  }

  if (error instanceof Error) {
    return [
      {
        status: unknownErrorStatusCode,
        title: error.message,
        detail: error.stack,
      },
    ];
  }

  return [
    {
      status: unknownErrorStatusCode,
      title: "Unknown error",
    },
  ];
};
