import { RouteType } from "@/types";

export const companySubpagesRouteTypes: RouteType[] = [
  RouteType.CompanyInformation,
  RouteType.CompanyDetailsView,
  RouteType.CompanyRatingsView,
  RouteType.CompanyContactsView,
  RouteType.CompanyInquiries,
];

export const getRouteTypeHref = (companyId: string | number | undefined, type: RouteType): string => {
  if (!companyId) return "";
  const urlParts: string[] = ["companies", companyId.toString()];
  switch (type) {
    case RouteType.CompanyInquiries:
      urlParts.push("inquiries");
      break;
    case RouteType.CompanyDetailsView:
      urlParts.push("details");
      break;
    case RouteType.CompanyContactsView:
      urlParts.push("contacts");
      break;
    case RouteType.CompanyRatingsView:
      urlParts.push("ratings");
      break;
  }
  return `/${urlParts.join("/")}`;
};
