import { CompanySearchResultResponse, ICompanyProfileSearchResultResponse } from "@capetec/companies-api-client";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./translations";

import { useCompanies_searchCompaniesQuery, useCompanies_searchCompanyProfilesQuery } from "@/api/companyApi";

import { AvailableCompaniesResultList } from "../availableCompaniesResultList";
import { MyCompanyProfilesResultList } from "../myCompanyProfilesResultList";

export type CompanyResultsProps = {
  searchQuery: string;
  onCompanySuggestionClick: (companySuggestion: CompanySearchResultResponse) => void;
  onCompanyProfileClick: (companyProfile: ICompanyProfileSearchResultResponse) => void;
};

export const CompanyResults = ({
  searchQuery,
  onCompanySuggestionClick,
  onCompanyProfileClick,
}: CompanyResultsProps): JSX.Element => {
  const { t } = useTranslation("CompanyResults");
  const pageSize = 4;

  const {
    data: companyProfilesPaginationData,
    isLoading: isCompaniesLoading,
    isError: isCompaniesError,
  } = useCompanies_searchCompanyProfilesQuery(
    {
      pageNumber: 1,
      pageSize: pageSize,
      name: searchQuery,
    },
    {
      skip: searchQuery === "",
    },
  );

  const {
    data: companySuggestionsPaginationData,
    isLoading: isCompanySuggestionsLoading,
    isFetching: isCompanySuggestionsFetching,
    isError: isCompanySuggestionsError,
  } = useCompanies_searchCompaniesQuery(
    {
      name: searchQuery,
    },
    {
      skip: !searchQuery,
      refetchOnMountOrArgChange: true,
    },
  );

  if (companySuggestionsPaginationData?.data.length === 0 && companyProfilesPaginationData?.data.length === 0) {
    return (
      <List>
        <ListItemButton>
          <ListItemText primary={t("noCompaniesFound")} />
        </ListItemButton>
      </List>
    );
  }

  return (
    <>
      <MyCompanyProfilesResultList
        onCompanyProfileClick={onCompanyProfileClick}
        companyProfilesPaginationData={companyProfilesPaginationData}
        isCompaniesLoading={isCompaniesLoading}
        isCompaniesError={isCompaniesError}
        pageSize={pageSize}
      />
      <AvailableCompaniesResultList
        onCompanySuggestionClick={onCompanySuggestionClick}
        companySuggestionsPaginationData={companySuggestionsPaginationData}
        isCompanySuggestionsLoading={isCompanySuggestionsLoading || isCompanySuggestionsFetching}
        isCompanySuggestionsError={isCompanySuggestionsError}
      />
    </>
  );
};
