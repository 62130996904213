import {
  AddResourceBundle,
  SupportedLanguage,
  SupportedLanguagesCode,
} from "./types";
import {
  FALLBACK_LANGUAGE_CODE,
  FALLBACK_SUPPORTED_LANGUAGE,
  supportedLanguages,
} from "./constants";
import i18next, { i18n } from "i18next";

const resolveLanguageCode = (
  language?: string,
): SupportedLanguagesCode | undefined =>
  language
    ? Object.values(SupportedLanguagesCode).find(
        (languageCode) => languageCode.toString() === language,
      )
    : undefined;

export const getLanguageCode = (
  language?: string,
  languageCodes?: SupportedLanguagesCode[],
): SupportedLanguagesCode => {
  const resolvedLanguageCode = resolveLanguageCode(language);
  if (
    resolvedLanguageCode &&
    languageCodes &&
    languageCodes.includes(resolvedLanguageCode)
  ) {
    return resolvedLanguageCode;
  }

  return FALLBACK_LANGUAGE_CODE;
};

export const getLanguage = (
  language?: string,
  languageCodes?: SupportedLanguagesCode[],
): Required<SupportedLanguage> => {
  const resolvedLanguageCode = resolveLanguageCode(language);
  if (
    languageCodes &&
    resolvedLanguageCode &&
    !languageCodes.includes(resolvedLanguageCode)
  )
    return FALLBACK_SUPPORTED_LANGUAGE;

  return {
    ...FALLBACK_SUPPORTED_LANGUAGE,
    ...supportedLanguages.find(
      (language) => language.code === resolvedLanguageCode,
    ),
  };
};

export const addResourceBundles = (
  addResourceBundles: AddResourceBundle[],
  i18n: i18n = i18next,
): void => {
  addResourceBundles.forEach(({ languageCode, namespace, resources }) => {
    i18n.addResourceBundle(languageCode, namespace, resources, true, true);
  });
};
