import { UserButton } from "@clerk/clerk-react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import { Box, Typography, useTheme } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import { CSSObject, SxProps, Theme, styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import "./translations";

import DvLogo from "@/assets/DV.webp";
import { HeaderSearchBar } from "@/features/searchBar";
import { useIsUpBreakpoint } from "@/hooks";
import { SidebarItems } from "@/types/SidebarItems";

import { SidebarItem } from "./SidebarItem";

const sidebarItemIcons = {
  Home: (style: SxProps<Theme>): JSX.Element => <SpaceDashboardOutlinedIcon sx={style} />,
  Tasks: (style: SxProps<Theme>): JSX.Element => <TaskOutlinedIcon sx={style} />,
  Companies: (style: SxProps<Theme>): JSX.Element => <DomainOutlinedIcon sx={style} />,
  Forms: (style: SxProps<Theme>): JSX.Element => <HelpCenterOutlinedIcon sx={style} />,
};

const sidebarItemLinks = {
  Home: "/",
  Tasks: "/tasks",
  Companies: "/companies",
  Forms: "/forms",
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.sidebar.drawerWidth,
  borderRadius: theme.sidebar.borderRadius,
  backgroundImage: theme.sidebar.background,
  borderRight: "none",

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  borderRadius: theme.sidebar.borderRadius,
  backgroundImage: theme.sidebar.background,
  borderRight: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: "90px",
  },
});

const DrawerHeader = styled("div", { shouldForwardProp: (prop) => prop !== "open" })<{
  open: boolean;
}>(({ theme, open }) => ({
  display: "flex",
  alignItems: open ? "center" : "flex-end",
  justifyContent: "center",
  height: "130px",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: theme.sidebar.drawerWidth,
    width: `calc(100% - ${theme.sidebar.drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: theme.sidebar.drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type SidebarProps = {
  open: boolean;
  isStartPage: boolean;
  setOpen: (open: boolean) => void;
};

export const Sidebar = ({ isStartPage, open, setOpen }: SidebarProps): JSX.Element => {
  const { t } = useTranslation("Sidebar");
  const theme = useTheme();
  const isUpSm = useIsUpBreakpoint("sm");
  const location = useLocation();
  const [selected, setSelected] = useState<SidebarItems>(SidebarItems.Home);

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    const sidebarItem = Object.values(SidebarItems).find((item) => item.toLowerCase() === path);
    if (sidebarItem) {
      setSelected(sidebarItem);
    }
  }, [location.pathname]);

  const handleOnClick = (sidebarItem: SidebarItems): void => {
    setSelected(sidebarItem);
  };

  const handleDrawerClick = (): void => {
    setOpen(!open);
  };

  const items = Object.values(SidebarItems);

  return (
    <>
      <AppBar sx={{ boxShadow: "0px 1px 1px #e8e8e8" }} position="relative" open={open}>
        <Toolbar
          sx={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "space-between",
            height: "60px",
            paddingLeft: "5px !important",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={handleDrawerClick}>{open ? <MenuOpenIcon /> : <ChevronRightIcon />}</IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ mr: 4 }}>{!isStartPage && <HeaderSearchBar />}</Box>
            <UserButton
              showName={isUpSm}
              appearance={{
                elements: {
                  userButtonBox: {
                    textTransform: "uppercase",
                  },
                },
              }}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-root": {
            position: "absolute",
          },
          "& .MuiPaper-root": {
            position: "absolute",
            borderRadius: "5px",
          },
        }}
      >
        <DrawerHeader open={open}>
          <IconButton component={Link} to={sidebarItemLinks.Home} onClick={() => handleOnClick(SidebarItems.Home)}>
            <img
              src={DvLogo}
              className="App-logo"
              alt="logo"
              style={{ height: "auto", width: open ? "60px" : "45px", paddingBottom: open ? 0 : "5px" }}
            />
          </IconButton>
        </DrawerHeader>
        <List>
          {items.map((item) => (
            <SidebarItem
              key={item}
              selected={selected === item}
              open={open}
              to={sidebarItemLinks[item]}
              label={t(item)}
              handleOnClick={() => handleOnClick(item)}
              icon={sidebarItemIcons[item]({
                color: selected === item ? theme.sidebar.textColor : theme.sidebar.secondaryTextColor,
              })}
            />
          ))}
        </List>
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            position: "absolute",
            bottom: 15,
            display: "flex",
            flexDirection: open ? "row" : "column",
            justifyContent: "space-around",
          }}
        >
          <Typography sx={{ color: "white" }} variant="caption">
            {t("dataPrivacy")}
          </Typography>
          <Typography sx={{ color: "white" }} variant="caption">
            {t("imprint")}
          </Typography>
        </Box>
      </Drawer>
    </>
  );
};
