import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector, { CustomDetector } from "i18next-browser-languagedetector";
import { addCommonResourceBundles, namespace } from "./translations";
import { FALLBACK_LANGUAGE_CODE } from "./constants";

// localization for api https://learn.microsoft.com/en-us/aspnet/core/fundamentals/localization/select-language-culture?view=aspnetcore-8.0
const cookieName = ".AspNetCore.Culture";

const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length < 2) return;

  const pop = parts.pop();
  if (!pop) return;

  return pop.split(";").shift();
};

const setLanguageCookie = (languageKey?: string): void => {
  if (!languageKey) return;
  document.cookie = `${cookieName}=c=${languageKey}|uic=${languageKey}; SameSite=None; Secure`;
};

const customDetector: CustomDetector = {
  name: "customDetector",

  lookup() {
    const cookie = getCookie(cookieName);
    if (!cookie) return FALLBACK_LANGUAGE_CODE;

    const result = cookie.match(new RegExp("c=(.*)uic")); // should be |uic, but don't know how to use | inside regex
    if (!result || result.length < 2) return FALLBACK_LANGUAGE_CODE;

    return result[1].substring(0, result[1].length - 1); // | at the end shouldn't be inside the culture code
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customDetector);

// TODO check error handling of async catch, solution found on https://stackoverflow.com/a/46515787
(async (): Promise<void> => {
  try {
    await i18n
      .use(languageDetector)
      .use(initReactI18next)
      .init({
        defaultNS: namespace,
        fallbackLng: FALLBACK_LANGUAGE_CODE,
        resources: {},
        detection: {
          order: ["customDetector", "cookie"],
          lookupCookie: cookieName,
        },
      });

    i18n.on("languageChanged", function (lng) {
      setLanguageCookie(lng);
    });

    // add standard resources on this, because if these are imported,
    // they will get executed before init of i18n is called and function addResourceBundle won't be available,
    // because it is created while init.
    addCommonResourceBundles(i18n);
  } catch (err) {
    console.error(err);
  }
})().catch((e) => {
  console.error(e);
});
