import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface SearchBarSliceState {
  searchQuery: string;
}

// Define the initial state using that type
const initialState: SearchBarSliceState = {
  searchQuery: "",
};

// Implement the slice
export const searchBarSlice = createSlice({
  name: "searchBar",
  // 'createSlice' will infer the state type from the 'initialState' argument
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
  },
});

export const { setSearchQuery } = searchBarSlice.actions;

export const SearchBarReducer = searchBarSlice.reducer;
