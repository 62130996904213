import { V1DocumentsApiClient } from "@capetec/documents-api-client";

import { getSessionToken } from "../clerkApi";

export const documentsClient = new V1DocumentsApiClient(
  {
    getToken: async (): Promise<string> => {
      const token = await getSessionToken();
      if (!token) {
        return "";
      }
      return `Bearer ${token}`;
    },
  },
  window.REACT_APP_DOCUMENT_API,
);
