import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import React from "react";

import { useLanguage } from "@/i18n";
import { useAppSelector } from "@/store/hooks";

import { themeCreator } from "./base";

export type ThemeProviderWrapperProps = {
  children: React.ReactNode;
};

export const ThemeProviderWrapper = ({ children }: ThemeProviderWrapperProps): JSX.Element => {
  const themeName = useAppSelector((state) => state.theme.themeName);
  const language = useLanguage();
  const theme = themeCreator(themeName, language);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
