import { MyNotificationsReadRequest, MyNotificationsResponse } from "@capetec/companies-api-client";
import { useAuth } from "@clerk/clerk-react";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useCompanies_completeNotificationsMutation, useCompanies_getMyNotificationsQuery } from "@/api/companyApi";
import { MyNotificationsDialog } from "@/components/myNotificationsDialog";
import { useCurrentRoute } from "@/hooks/useCurrentRoute";
import { companySubpagesRouteTypes } from "@/routes/constants";
import { RouteType } from "@/types";

import { Header } from "./header";
import { LoadingClerk } from "./loadingClerk";
import { Sidebar } from "./sidebar";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme }) => ({
  position: "relative",
  flexGrow: 1,
  minHeight: 0,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `${theme.sidebar.drawerWidthClosed}px`,
  variants: [
    {
      props: ({ open }): boolean | undefined => open,
      style: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `${theme.sidebar.drawerWidth}px`,
      },
    },
  ],
}));

export const PrivateLayout = (): JSX.Element => {
  const { userId, isLoaded } = useAuth();
  const [open, setOpen] = useState(true);
  const [openNotificationPopup, setOpenNotificationPopup] = useState(true);
  const [notification, setNotification] = useState<MyNotificationsResponse | undefined>(undefined);
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const isStartPage = useMemo(
    () => !currentRoute || [RouteType.StartPage, RouteType.Layout].includes(currentRoute.handle.type),
    [currentRoute],
  );
  const isCompanyPage = useMemo(
    () => !currentRoute || companySubpagesRouteTypes.includes(currentRoute.handle.type),
    [currentRoute],
  );
  const isInquiryPage = useMemo(
    () => !currentRoute || currentRoute.handle.type === RouteType.CompanyInquiry,
    [currentRoute],
  );
  const isCalculatorPage = useMemo(
    () => !currentRoute || currentRoute.handle.type === RouteType.CompanyCalculators,
    [currentRoute],
  );
  const [completeNotification] = useCompanies_completeNotificationsMutation();

  const executeCompleteNotification = useCallback(
    async (userNotificationId: number) => {
      await completeNotification(new MyNotificationsReadRequest({ userNotificationId }));
    },
    [completeNotification],
  );

  const { data: companyProfileNotifications, isLoading: isCompanyProfileNotificationsLoading } =
    useCompanies_getMyNotificationsQuery(undefined);

  useEffect(() => {
    if (
      !isCompanyProfileNotificationsLoading &&
      companyProfileNotifications &&
      companyProfileNotifications.data.length > 0
    ) {
      setNotification(companyProfileNotifications.data[0]);
      setOpenNotificationPopup(true);
    }
  }, [companyProfileNotifications, isCompanyProfileNotificationsLoading]);

  useEffect(() => {
    if (isLoaded && !userId) {
      navigate("/sign-in");
    }
  }, [isLoaded, navigate, userId]);

  if (!isLoaded || (isLoaded && !userId)) return <LoadingClerk />;

  const handleClose = (notification: MyNotificationsResponse): void => {
    setOpenNotificationPopup(false);
    executeCompleteNotification(notification.id);
  };

  return (
    <>
      <Sidebar isStartPage={isStartPage} open={open} setOpen={setOpen} />
      <Header
        isCalculatorPage={isCalculatorPage}
        isInquiryPage={isInquiryPage}
        isCompanyPage={isCompanyPage}
        open={open}
      />
      <Main open={open}>
        <Outlet />
        {notification && (
          <MyNotificationsDialog notification={notification} open={openNotificationPopup} handleClose={handleClose} />
        )}
      </Main>
    </>
  );
};
