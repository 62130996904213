import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, CircularProgress, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { ReactNode } from "react";

export type ListItemGroupHeaderButtonProps = {
  isLoading: boolean;
  isOpen: boolean;
  title: string;
  icon: ReactNode;
  onClick: () => void;
};

export const ListItemGroupHeaderButton = ({
  isLoading,
  isOpen,
  title,
  icon,
  onClick,
}: ListItemGroupHeaderButtonProps): JSX.Element => {
  return (
    <ListItemButton onClick={onClick} color="primary">
      <Box sx={{ position: "relative" }}>
        <ListItemIcon>{icon}</ListItemIcon>
        {isLoading && (
          <CircularProgress
            sx={{
              position: "absolute",
              top: -7,
              left: -7,
              zIndex: 1,
            }}
          />
        )}
      </Box>
      <ListItemText primary={<Typography sx={{ fontWeight: "bold" }}>{title}</Typography>} color="primary" />
      <ListItemIcon sx={{ pointerEvents: "none" }} color="primary">
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemIcon>
    </ListItemButton>
  );
};
