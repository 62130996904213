import { FormControlLabel, FormControlLabelProps, Radio, Stack, Typography } from "@mui/material";
import { useRadioGroup } from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import React from "react";

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => <FormControlLabel {...props} />)(
  ({ theme }) => ({
    variants: [
      {
        props: { checked: true },
        style: {
          ".MuiFormControlLabel-label": {
            color: "#888",
            fontSize: "10px",
            padding: "20px",
            height: "120px",
            width: "150px",
            borderRadius: "10px",
            marginLeft: "10px",
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: "#F1F5F9",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          },
        },
      },
      {
        props: { checked: false },
        style: {
          ".MuiFormControlLabel-label": {
            color: "#888",
            fontSize: "10px",
            padding: "20px",
            borderRadius: "10px",
            marginLeft: "10px",
            backgroundColor: "#F1F5F9",
            height: "120px",
            width: "150px",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              border: `1px solid ${theme.palette.primary.main}`,
            },
          },
        },
      },
    ],
  }),
);

interface FormControlRadioProps extends Omit<FormControlLabelProps, "control"> {
  icon: React.ReactNode;
  control?: JSX.Element;
}

export const FormControlCardRadio = ({
  icon,
  control = <Radio sx={{ opacity: 0, position: "absolute", width: 0, height: 0 }} />,
  ...props
}: FormControlRadioProps): JSX.Element => {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return (
    <StyledFormControlLabel
      checked={checked}
      control={control}
      {...props}
      label={
        <Stack spacing={1} alignItems="center">
          {icon}
          <Typography variant="body2">{props.label}</Typography>
        </Stack>
      }
    />
  );
};
