import { isArray } from "lodash";
import { DateTime } from "luxon";

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d*)?Z?$/;

export const parseAndConvertDates = (value: unknown): unknown => {
  if (typeof value === "string" && dateFormat.test(value)) {
    return new Date(value);
  }

  const luxonDateTime =
    typeof value === "string"
      ? DateTime.fromISO(value)
      : typeof value === "object" && value?.toString != null
        ? DateTime.fromJSDate(new Date(value.toString()))
        : undefined;
  if (luxonDateTime?.isValid) {
    return luxonDateTime;
  }

  return value;
};
interface StringKeyValueType {
  [key: string]: unknown;
}

export function deserializeDatesInObject<Output>(body: Output): Output {
  if (body === null || body === undefined || typeof body !== "object") {
    return body;
  }

  if (isArray(body)) {
    const adjustedBodyArray = [...body];
    const adjustedBodyArrayLength = adjustedBodyArray.length;

    for (
      let adjustedBodyArrayIterator = 0;
      adjustedBodyArrayIterator < adjustedBodyArrayLength;
      adjustedBodyArrayIterator += 1
    ) {
      adjustedBodyArray[adjustedBodyArrayIterator] = deserializeDatesInObject(
        adjustedBodyArray[adjustedBodyArrayIterator],
      );
    }

    return adjustedBodyArray as unknown as Output;
  }

  const adjustedBody = { ...body } as unknown as StringKeyValueType;

  const keys = Object.keys(adjustedBody);
  const keysLength = keys.length;

  for (let keysIterator = 0; keysIterator < keysLength; keysIterator += 1) {
    const key = keys[keysIterator];
    const value = adjustedBody[key];
    const luxonDateTime =
      typeof value === "string"
        ? DateTime.fromISO(value)
        : typeof value === "object" && value?.toString != null
          ? DateTime.fromJSDate(new Date(value.toString()))
          : undefined;
    if (typeof value === "string" && dateFormat.test(value)) {
      adjustedBody[key] = new Date(value);
    } else if (luxonDateTime?.isValid) {
      adjustedBody[key] = luxonDateTime;
    } else if (typeof value === "object") {
      adjustedBody[key] = deserializeDatesInObject(value);
    }
  }
  return adjustedBody as unknown as Output;
}

export const datesJsonParseReviver = (value: unknown): unknown => {
  if (typeof value === "string" && dateFormat.test(value)) {
    return new Date(value);
  }

  const luxonDateTime =
    typeof value === "string"
      ? DateTime.fromISO(value)
      : typeof value === "object" && value?.toString != null
        ? DateTime.fromJSDate(new Date(value.toString()))
        : undefined;
  if (luxonDateTime?.isValid) {
    return luxonDateTime;
  }

  return value;
};
