import { V1CompaniesApiClient } from "@capetec/companies-api-client";

import { getSessionToken } from "../clerkApi";

export const companiesClient = new V1CompaniesApiClient(
  {
    getToken: async (): Promise<string> => {
      const token = await getSessionToken();
      if (!token) {
        return "";
      }
      return `Bearer ${token}`;
    },
  },
  window.REACT_APP_COMPANY_API,
);
