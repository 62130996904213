import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Collapse, Dialog, DialogContent, IconButton, InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./translations";

import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { setSearchQuery } from "../../searchBarSlice";
import { EmptySearchQueryContent } from "./emptySearchQueryContent";
import { SearchQueryContent } from "./searchQueryContent";

type HeaderSearchBarDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const HeaderSearchBarDialog = ({ open, onClose }: HeaderSearchBarDialogProps): JSX.Element => {
  const { t } = useTranslation("SearchBar");
  const dispatch = useAppDispatch();

  const searchQuery = useAppSelector((state) => state.searchBar.searchQuery);

  const handleClose = (): void => {
    onClose();
  };

  const onSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSearchQuery = e.target.value;
    dispatch(setSearchQuery(newSearchQuery));
  };

  const handleCloseDialog = (): void => {
    onClose();
  };

  const onItemOpenClick = (): void => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      disableRestoreFocus
      fullWidth
      sx={{
        display: "flex",
        flexDirection: "column",
        "& .MuiDialog-paper": {
          minHeight: "50vh",
          maxHeight: "50vh",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          p: 2,
        }}
      >
        <TextField
          autoFocus
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: <InputAdornment position="end"></InputAdornment>,
          }}
          variant="standard"
          value={searchQuery}
          onChange={onSearchQueryChange}
          sx={{
            flex: 1,
          }}
          placeholder={t("placeholder")}
        />
        <IconButton onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flex: 1, flexDirection: "column", pt: 0 }}>
        <Collapse in={searchQuery === ""} mountOnEnter unmountOnExit>
          <EmptySearchQueryContent onItemOpenClick={onItemOpenClick} />
        </Collapse>
        <Collapse in={searchQuery !== ""} mountOnEnter unmountOnExit>
          <SearchQueryContent onItemOpenClick={onItemOpenClick} />
        </Collapse>
      </DialogContent>
    </Dialog>
  );
};
