import { CreateInquiryRequest, IInquiryResponse, InquiryOffersResponse } from "@capetec/inquiries-api-client";

import { TagType } from "@/api/enums";

import { provideTagsForIInquiryResponse, transformApiPaginationResponse } from "../helpers";
import { inquiryApi } from "../inquiryApi";
import {
  GetInquiriesParameters,
  GetInquiryByIdRequest,
  GetInquiryOffersRequest,
  InquiriesApiResponse,
  InquiryPatchInquiryFinancingRequestRequest,
  InquiryPatchPurposeOfUseRequest,
  PaginationResponse,
} from "../types";

export const inquiriesController = inquiryApi.injectEndpoints({
  endpoints: (builder) => ({
    inquiries_getInquiryById: builder.query<IInquiryResponse | undefined, GetInquiryByIdRequest>({
      query: ({ inquiryId, params }) => ({
        apiMethod: async (client): Promise<InquiriesApiResponse<IInquiryResponse>> => {
          const inquiryResponse = await client.inquiries_GetInquiryById(inquiryId, params?.include);

          if (inquiryResponse.errors) {
            return {
              errors: inquiryResponse.errors,
              meta: inquiryResponse.meta,
            };
          }

          return {
            data: inquiryResponse.data,
            meta: inquiryResponse.meta,
          };
        },
      }),
      providesTags: provideTagsForIInquiryResponse,
    }),
    inquiries_getInquiryByIdForWizard: builder.query<IInquiryResponse | undefined, number>({
      query: (inquiryId) => ({
        apiMethod: async (client): Promise<InquiriesApiResponse<IInquiryResponse>> => {
          const inquiryResponse = await client.inquiries_GetWizardInquiryById(inquiryId);

          if (inquiryResponse.errors) {
            return {
              errors: inquiryResponse.errors,
              meta: inquiryResponse.meta,
            };
          }

          return {
            data: inquiryResponse.data,
            meta: inquiryResponse.meta,
          };
        },
      }),
      providesTags: provideTagsForIInquiryResponse,
    }),
    inquiries_getInquiries: builder.query<PaginationResponse<IInquiryResponse> | undefined, GetInquiriesParameters>({
      query: ({ include, companyProfileId: inquiryId, sortBy, sortDirection, pageNumber, pageSize, filterStatus }) => ({
        apiMethod: async (client): Promise<InquiriesApiResponse<PaginationResponse<IInquiryResponse>>> => {
          const inquiryResponse = await client.inquiries_GetInquiries(
            inquiryId,
            include,
            sortDirection,
            sortBy,
            filterStatus,
            pageNumber,
            pageSize,
          );
          return transformApiPaginationResponse(inquiryResponse);
        },
      }),
      providesTags: (result) =>
        result?.data?.length
          ? result.data.map((result) => ({ type: TagType.InquiryApi_InquiryResponse, id: result.id }))
          : [TagType.InquiryApi_InquiryResponse],
    }),
    inquiries_getInquiryOffers: builder.query<InquiryOffersResponse | undefined, GetInquiryOffersRequest>({
      query: ({ inquiryId }) => ({
        apiMethod: async (client): Promise<InquiriesApiResponse<InquiryOffersResponse>> => {
          const inquiryOffersResponse = await client.inquiries_GetInquiryOffers(inquiryId);

          if (inquiryOffersResponse.errors) {
            return {
              errors: inquiryOffersResponse.errors,
              meta: inquiryOffersResponse.meta,
            };
          }

          return {
            data: inquiryOffersResponse.data,
            meta: inquiryOffersResponse.meta,
          };
        },
      }),
      providesTags: (result) =>
        result?.offers?.length
          ? result.offers.map((result) => ({ type: TagType.InquiryApi_InquiryOffersResponse, id: result.id }))
          : [TagType.InquiryApi_InquiryOffersResponse],
    }),
    inquiries_createInquiry: builder.mutation<IInquiryResponse | undefined, CreateInquiryRequest>({
      query: (body) => ({
        apiMethod: async (client): Promise<InquiriesApiResponse<IInquiryResponse | undefined>> => {
          const companyProfileResponse = await client.inquiries_CreateInquiry(body);

          if (companyProfileResponse.errors) {
            return {
              errors: companyProfileResponse.errors,
              meta: companyProfileResponse.meta,
            };
          }

          return {
            data: companyProfileResponse.data,
            meta: companyProfileResponse.meta,
          };
        },
      }),
      invalidatesTags: [TagType.InquiryApi_InquiryResponse],
    }),
    inquiries_patchInquiryPurposeOfUse: builder.mutation<IInquiryResponse | undefined, InquiryPatchPurposeOfUseRequest>(
      {
        query: ({ inquiryId, body }) => ({
          apiMethod: async (client): Promise<InquiriesApiResponse<IInquiryResponse | undefined>> => {
            const inquiryResponse = await client.inquiries_PatchInquiryPurposeOfUse(inquiryId, body);

            if (inquiryResponse.errors) {
              return {
                errors: inquiryResponse.errors,
                meta: inquiryResponse.meta,
              };
            }

            return {
              data: inquiryResponse.data,
              meta: inquiryResponse.meta,
            };
          },
        }),
        invalidatesTags: provideTagsForIInquiryResponse,
      },
    ),
    inquiries_patchInquiryFinancingRequest: builder.mutation<
      IInquiryResponse | undefined,
      InquiryPatchInquiryFinancingRequestRequest
    >({
      query: ({ inquiryId, body }) => ({
        apiMethod: async (client): Promise<InquiriesApiResponse<IInquiryResponse | undefined>> => {
          const inquiryResponse = await client.inquiries_PatchInquiryFinancingRequest(inquiryId, body);

          if (inquiryResponse.errors) {
            return {
              errors: inquiryResponse.errors,
              meta: inquiryResponse.meta,
            };
          }

          return {
            data: inquiryResponse.data,
            meta: inquiryResponse.meta,
          };
        },
      }),
      invalidatesTags: provideTagsForIInquiryResponse,
    }),
    inquiries_patchInquirySummary: builder.mutation<IInquiryResponse | undefined, number>({
      query: (inquiryId) => ({
        apiMethod: async (client): Promise<InquiriesApiResponse<IInquiryResponse | undefined>> => {
          const inquiryResponse = await client.inquiries_PatchInquirySummary(inquiryId);

          if (inquiryResponse.errors) {
            return {
              errors: inquiryResponse.errors,
              meta: inquiryResponse.meta,
            };
          }

          return {
            data: inquiryResponse.data,
            meta: inquiryResponse.meta,
          };
        },
      }),
      invalidatesTags: provideTagsForIInquiryResponse,
    }),
  }),
});

export const {
  useInquiries_getInquiriesQuery,
  useInquiries_getInquiryByIdQuery,
  useInquiries_createInquiryMutation,
  useInquiries_patchInquiryPurposeOfUseMutation,
  useInquiries_getInquiryByIdForWizardQuery,
  useInquiries_patchInquiryFinancingRequestMutation,
  useInquiries_patchInquirySummaryMutation,
  useInquiries_getInquiryOffersQuery,
} = inquiriesController;
