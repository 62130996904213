import {
  LookupAddressTypeResponse,
  LookupAnnualNetProfitClassResponse,
  LookupCommunicationInformationTypeResponse,
  LookupCompanyProfileStatusResponse,
  LookupCountryResponse,
  LookupEconomicSectorResponse,
  LookupExternalIdTypeResponse,
  LookupLegalFormResponse,
  LookupRatingAgencyResponse,
  LookupRatingDisplayTextResponse,
  LookupRatingReportTypeResponse,
  LookupRegisterCourtResponse,
  LookupRegisterTypeResponse,
  LookupSalutationResponse,
  LookupTurnoverClassResponse,
} from "@capetec/companies-api-client";

import { TagType } from "@/api/enums";

import { companyApi } from "../companyApi";
import { transformApiPaginationResponse } from "../helpers";
import { CompaniesApiResponse, PaginationResponse } from "../types";
import { GetCreditRatingDisplayTextParameters } from "../types/lookupController";

export const lookupController = companyApi.injectEndpoints({
  endpoints: (builder) => ({
    lookup_getAddressTypes: builder.query<PaginationResponse<LookupAddressTypeResponse> | undefined, void>({
      query: () => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<LookupAddressTypeResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetAddressTypes()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_AddressTypeResponse, id: lookup.id })) || [
          TagType.CompanyApi_AddressTypeResponse,
        ],
    }),
    lookup_getAnnualNetProfitClasses: builder.query<
      PaginationResponse<LookupAnnualNetProfitClassResponse> | undefined,
      void
    >({
      query: () => ({
        apiMethod: async (
          client,
        ): Promise<CompaniesApiResponse<PaginationResponse<LookupAnnualNetProfitClassResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetAnnualNetProfitClasses()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_AnnualNetProfitClassResponse, id: lookup.id })) || [
          TagType.CompanyApi_AnnualNetProfitClassResponse,
        ],
    }),
    lookup_getCommunicationInformationTypes: builder.query<
      PaginationResponse<LookupCommunicationInformationTypeResponse> | undefined,
      void
    >({
      query: () => ({
        apiMethod: async (
          client,
        ): Promise<CompaniesApiResponse<PaginationResponse<LookupCommunicationInformationTypeResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetCommunicationInformationTypes()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({
          type: TagType.CompanyApi_CommunicationInformationTypeResponse,
          id: lookup.id,
        })) || [TagType.CompanyApi_CommunicationInformationTypeResponse],
    }),
    lookup_getCompanyProfileStatuses: builder.query<
      PaginationResponse<LookupCompanyProfileStatusResponse> | undefined,
      void
    >({
      query: () => ({
        apiMethod: async (
          client,
        ): Promise<CompaniesApiResponse<PaginationResponse<LookupCompanyProfileStatusResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetCompanyProfileStatus()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_CompanyProfileStatusResponse, id: lookup.id })) || [
          TagType.CompanyApi_CompanyProfileStatusResponse,
        ],
    }),
    lookup_getCountries: builder.query<PaginationResponse<LookupCountryResponse> | undefined, void>({
      query: () => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<LookupCountryResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetCountries()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_CountryResponse, id: lookup.id })) || [
          TagType.CompanyApi_CountryResponse,
        ],
    }),
    lookup_getEconomicSectors: builder.query<PaginationResponse<LookupEconomicSectorResponse> | undefined, void>({
      query: () => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<LookupEconomicSectorResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetEconomicSectors()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_EconomicSectorResponse, id: lookup.id })) || [
          TagType.CompanyApi_EconomicSectorResponse,
        ],
    }),
    lookup_getExternalIdTypes: builder.query<PaginationResponse<LookupExternalIdTypeResponse> | undefined, void>({
      query: () => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<LookupExternalIdTypeResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetExternalIdTypes()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_ExternalIdTypeResponse, id: lookup.id })) || [
          TagType.CompanyApi_ExternalIdTypeResponse,
        ],
    }),
    lookup_getLegalForms: builder.query<PaginationResponse<LookupLegalFormResponse> | undefined, number>({
      query: (countryId) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<LookupLegalFormResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetLegalForms(countryId)),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_LegalFormResponse, id: lookup.id })) || [
          TagType.CompanyApi_LegalFormResponse,
        ],
    }),
    lookup_getRatingAgencies: builder.query<PaginationResponse<LookupRatingAgencyResponse> | undefined, void>({
      query: () => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<LookupRatingAgencyResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetRatingAgencies()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_RatingAgencyResponse, id: lookup.id })) || [
          TagType.CompanyApi_RatingAgencyResponse,
        ],
    }),
    lookup_getRatingReportTypes: builder.query<PaginationResponse<LookupRatingReportTypeResponse> | undefined, void>({
      query: () => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<LookupRatingReportTypeResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetRatingReportTypes()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_RatingReportTypeResponse, id: lookup.id })) || [
          TagType.CompanyApi_RatingReportTypeResponse,
        ],
    }),
    lookup_getRegisterCourtes: builder.query<PaginationResponse<LookupRegisterCourtResponse> | undefined, void>({
      query: () => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<LookupRegisterCourtResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetRegisterCourts()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_RegisterCourtResponse, id: lookup.id })) || [
          TagType.CompanyApi_RegisterCourtResponse,
        ],
    }),
    lookup_getRegisterTypes: builder.query<PaginationResponse<LookupRegisterTypeResponse> | undefined, void>({
      query: () => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<LookupRegisterTypeResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetRegisterTypes()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_RegisterTypeResponse, id: lookup.id })) || [
          TagType.CompanyApi_RegisterTypeResponse,
        ],
    }),
    lookup_getSalutations: builder.query<PaginationResponse<LookupSalutationResponse> | undefined, void>({
      query: () => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<LookupSalutationResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetSalutations()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_SalutationResponse, id: lookup.id })) || [
          TagType.CompanyApi_SalutationResponse,
        ],
    }),
    lookup_getTurnoverClasses: builder.query<PaginationResponse<LookupTurnoverClassResponse> | undefined, void>({
      query: () => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<LookupTurnoverClassResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetTurnoverClasses()),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({ type: TagType.CompanyApi_TurnoverClassResponse, id: lookup.id })) || [
          TagType.CompanyApi_TurnoverClassResponse,
        ],
    }),
    lookup_getRatingDisplayText: builder.query<
      PaginationResponse<LookupRatingDisplayTextResponse> | undefined,
      GetCreditRatingDisplayTextParameters
    >({
      query: ({ rating }) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<LookupRatingDisplayTextResponse>>> =>
          transformApiPaginationResponse(await client.lookup_GetRatingDisplayText(rating)),
      }),
      providesTags: (result) =>
        result?.data?.map((lookup) => ({
          type: TagType.CompanyApi_CreditRatingDisplayResponse,
          id: lookup.rating,
        })) || [TagType.CompanyApi_CreditRatingDisplayResponse],
    }),
  }),
});

export const {
  useLookup_getAddressTypesQuery,
  useLookup_getAnnualNetProfitClassesQuery,
  useLookup_getCommunicationInformationTypesQuery,
  useLookup_getCompanyProfileStatusesQuery,
  useLookup_getCountriesQuery,
  useLookup_getEconomicSectorsQuery,
  useLookup_getExternalIdTypesQuery,
  useLookup_getLegalFormsQuery,
  useLookup_getRatingAgenciesQuery,
  useLookup_getRatingReportTypesQuery,
  useLookup_getRegisterCourtesQuery,
  useLookup_getRegisterTypesQuery,
  useLookup_getSalutationsQuery,
  useLookup_getTurnoverClassesQuery,
  useLookup_getRatingDisplayTextQuery,
} = lookupController;
