import { Theme, createTheme } from "@mui/material/styles";

import { SupportedLanguage } from "@/i18n";

import { DarkTheme } from "./themes/DarkTheme";
import { LightTheme } from "./themes/LightTheme";

const themeMap: Record<string, (...args: object[]) => Theme> = {
  DarkTheme,
  LightTheme,
};

export function themeCreator(theme: string, language: SupportedLanguage): Theme {
  const createdTheme = themeMap[theme];
  const themeArgs: object[] = [
    ...(language.dataGridLocalization ? [language.dataGridLocalization] : []),
    ...(language.xDatePickersLocale ? [language.xDatePickersLocale] : []),
    ...(language.muiLocalization ? [language.muiLocalization] : []),
  ];

  if (createdTheme) return createdTheme(...themeArgs);
  return createTheme(undefined, themeArgs);
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    primaryChip: true;
    secondaryChip: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primaryButton: true;
    greenPrimaryButton: true;
  }
}

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    companyProfileCard: true;
    transparentCompanyProfileCard: true;
    primaryCard: true;
    greyCard: true;
    greyCardSelect: true;
    calculatorButton: true;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxl: true;
  }
  interface ThemeOptions {
    sidebar: {
      background: string;
      borderRadius: string;
      textColor: string;
      secondaryTextColor: string;
      drawerWidth: number;
      drawerWidthClosed: number;
    };
  }
  interface Theme {
    sidebar: {
      background: string;
      borderRadius: string;
      textColor: string;
      secondaryTextColor: string;
      drawerWidth: number;
      drawerWidthClosed: number;
    };
  }
}
