/**
 * File is used to add configuration values to window variable.
 * Needs to be imported before something wants to use window values.
 */

declare global {
  interface Window {
    REACT_APP_ENVIRONMENT: string;
    REACT_APP_COMPANY_API: string;
    REACT_APP_CLERK_PUBLISHABLE_KEY: string;
    REACT_APP_CLERK_AUTH_URL: string;
    REACT_APP_INQUIRY_API: string;
    REACT_APP_DOCUMENT_API: string;
    REACT_APP_PRODUCT_API: string;
    isSandbox: () => boolean;
    isDevelopement: () => boolean;
    isProduction: () => boolean;
    isStaging: () => boolean;
    isTest: () => boolean;
    isProductionLike: () => boolean;

    Clerk?: {
      session?: {
        getToken(): Promise<string | null>;
      };
    };
  }
}

window.REACT_APP_ENVIRONMENT = window.REACT_APP_ENVIRONMENT || "";
window.REACT_APP_COMPANY_API = window.REACT_APP_COMPANY_API || "";
window.REACT_APP_INQUIRY_API = window.REACT_APP_INQUIRY_API || "";
window.REACT_APP_DOCUMENT_API = window.REACT_APP_DOCUMENT_API || "";
window.REACT_APP_PRODUCT_API = window.REACT_APP_PRODUCT_API || "";
window.REACT_APP_CLERK_PUBLISHABLE_KEY = window.REACT_APP_CLERK_PUBLISHABLE_KEY || "";
window.REACT_APP_CLERK_AUTH_URL = window.REACT_APP_CLERK_AUTH_URL || "";
window.isSandbox = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === "sandbox";
window.isDevelopement = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === "development";
window.isProduction = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === "production";
window.isStaging = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === "staging";
window.isTest = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === "test";
window.isProductionLike = (): boolean => window.isProduction() || window.isStaging();
