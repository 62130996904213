import { IInquiryResponse } from "@capetec/inquiries-api-client";
import { TagDescription } from "@reduxjs/toolkit/query";

import { TagType } from "@/api/enums";

import { PaginationResponse } from "../types";

export const provideTagsForPaginationResponseIInquiryResponse = (
  result: PaginationResponse<IInquiryResponse> | undefined,
): ReadonlyArray<TagDescription<TagType>> =>
  result?.data != null
    ? result.data.reduce(
        (prev, current: IInquiryResponse) => {
          const result = [...prev];
          if (current != null) {
            result.push(...provideTagsForIInquiryResponse(current));
          }
          return result;
        },
        [] as ReadonlyArray<TagDescription<TagType>>,
      )
    : [TagType.InquiryApi_InquiryResponse];

export const provideTagsForIInquiryResponse = (
  result: IInquiryResponse | undefined,
): ReadonlyArray<TagDescription<TagType>> => {
  if (result == null) return [TagType.InquiryApi_InquiryResponse];
  const tags = [{ type: TagType.InquiryApi_InquiryResponse, id: result.id }];
  if (result.factoringBillsRange && result.factoringBillsRangeId != null)
    tags.push({ type: TagType.InquiryApi_LookupFactoringBillsRangeResponse, id: result.factoringBillsRangeId });
  if (result.inquiryCollaterals)
    tags.push(
      ...result.inquiryCollaterals.map((collateral) => ({
        type: TagType.InquiryApi_InquiryCollateralResponse,
        id: collateral.id,
      })),
    );
  if (result.inquiryPurposeGood)
    tags.push({
      type: TagType.InquiryApi_InquiryPurposeGoodResponse,
      id: result.inquiryPurposeGood.id,
    });
  if (result.inquiryPurposeMachine)
    tags.push({
      type: TagType.InquiryApi_InquiryPurposeMachineResponse,
      id: result.inquiryPurposeMachine.id,
    });
  if (result.inquiryPurposeVehicle)
    tags.push({
      type: TagType.InquiryApi_InquiryPurposeVehicleResponse,
      id: result.inquiryPurposeVehicle.id,
    });
  if (result.loanType && result.loanTypeId != null)
    tags.push({ type: TagType.InquiryApi_LookupLoanTypeResponse, id: result.loanTypeId });

  return tags;
};
