import { V1ProductsApiClient } from "@capetec/products-api-client";

import { getSessionToken } from "../clerkApi";

export const productsClient = new V1ProductsApiClient(
  {
    getToken: async (): Promise<string> => {
      const token = await getSessionToken();
      if (!token) {
        return "";
      }
      return `Bearer ${token}`;
    },
  },
  window.REACT_APP_PRODUCT_API,
);
