import { CompanyProfileSearchResultResponse, ICompanyProfileSearchResultResponse } from "@capetec/companies-api-client";
import StoreIcon from "@mui/icons-material/Store";
import { Alert } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./translations";

import { PaginationResponse } from "@/api/companyApi/types";
import { LoadingList } from "@/components";
import { ListItemGroupHeaderButton } from "@/components/listItemGroupHeaderButton";

type MyCompanyProfilesResultListProps = {
  companyProfilesPaginationData?: PaginationResponse<CompanyProfileSearchResultResponse>;
  onCompanyProfileClick: (companyProfile: ICompanyProfileSearchResultResponse) => void;
  isCompaniesError: boolean;
  isCompaniesLoading: boolean;
  pageSize: number;
};

export const MyCompanyProfilesResultList = ({
  companyProfilesPaginationData,
  isCompaniesLoading,
  isCompaniesError,
  onCompanyProfileClick,
  pageSize,
}: MyCompanyProfilesResultListProps): JSX.Element => {
  const [myCompanyProfilesResultOpen, setMyCompanyProfilesResultOpen] = useState(true);
  const { t } = useTranslation("MyCompanyProfilesResultList");
  const handleToggleGroupOpenClick = (): void => {
    setMyCompanyProfilesResultOpen(!myCompanyProfilesResultOpen);
  };

  return (
    <>
      {!companyProfilesPaginationData?.data ||
        (companyProfilesPaginationData.data.length > 0 && (
          <>
            <ListItemGroupHeaderButton
              icon={<StoreIcon color="primary" />}
              isLoading={isCompaniesLoading}
              isOpen={myCompanyProfilesResultOpen}
              onClick={handleToggleGroupOpenClick}
              title={t("myCompanies")}
            />
            <Collapse in={myCompanyProfilesResultOpen} timeout="auto" unmountOnExit>
              {isCompaniesError ? (
                <Alert sx={{ my: 1 }} color="error">
                  {t("loadingError")}
                </Alert>
              ) : (
                <List component="div" disablePadding>
                  {isCompaniesLoading ? (
                    <LoadingList itemAmount={pageSize} height="30px" />
                  ) : (
                    companyProfilesPaginationData.data.map((companyProfile) => (
                      <ListItemButton
                        sx={{ pl: 4 }}
                        key={companyProfile.id}
                        onClick={() => onCompanyProfileClick(companyProfile)}
                        component={Link}
                        to={`/companies/${companyProfile.id}`}
                      >
                        <ListItemText primary={companyProfile.name ?? "-"} />
                      </ListItemButton>
                    ))
                  )}
                </List>
              )}
            </Collapse>
          </>
        ))}
    </>
  );
};
