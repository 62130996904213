import { i18n } from "i18next";

import { de as applicationDe, en as applicationEn } from "./application";
import { de as buttonsDe, en as buttonsEn } from "./buttons";
import { de as companyDe, en as companyEn } from "./company";
import { de as errorsDe, en as errorsEn } from "./errors";
import { de as offersDe, en as offersEn } from "./offers";
import { de as pagesDe, en as pagesEn } from "./pages";
import { de as productsDe, en as productsEn } from "./products";

export const namespace = "Common";

const de = {
  pages: pagesDe,
  products: productsDe,
  offers: offersDe,
  errors: errorsDe,
  company: companyDe,
  buttons: buttonsDe,
  application: applicationDe,
};

const en = {
  pages: pagesEn,
  products: productsEn,
  offers: offersEn,
  errors: errorsEn,
  company: companyEn,
  buttons: buttonsEn,
  application: applicationEn,
};

declare module "i18next" {
  // defining custom interface, which can be extended inside each index of translation folder.
  // this is needed, because not possible to redefine every time types of properties
  interface CustomTypeResources {
    [namespace]: typeof de;
  }

  interface CustomTypeOptions {
    defaultNS: typeof namespace;
    // custom resources type
    resources: CustomTypeResources;
  }
}

export const addCommonResourceBundles = (i18n: i18n): void => {
  i18n.addResourceBundle("de", namespace, de, true, true);
  i18n.addResourceBundle("en", namespace, en, true, true);
};

export * from "./errors/errorEnums";
