import { InquiryFinancingTypeValue, InquiryPurposeValue } from "@capetec/inquiries-api-client";

export const REAL_ESTATE_AND_BUSINESS_FINANCING: InquiryPurposeValue[] = [
  InquiryPurposeValue.RealEstate,
  InquiryPurposeValue.ShareAcquisition,
  InquiryPurposeValue.Goods,
  InquiryPurposeValue.DebtRescheduling,
  InquiryPurposeValue.Liquidity,
];

export const isRealEstateAndBusinessFinancing = (purpose: InquiryPurposeValue): boolean => REAL_ESTATE_AND_BUSINESS_FINANCING.includes(purpose);

export const VEHICLE_AND_MACHINERY_FINANCING: InquiryPurposeValue[] = [
  InquiryPurposeValue.Vehicle,
  InquiryPurposeValue.Machine,
];
export const isVehicleAndMachineryFinancing = (purpose: InquiryPurposeValue): boolean => VEHICLE_AND_MACHINERY_FINANCING.includes(purpose);

export const FACTORING_FINANCING: InquiryPurposeValue[] = [InquiryPurposeValue.Factoring];
export const isFactoringFinancing = (purpose: InquiryPurposeValue): boolean => FACTORING_FINANCING.includes(purpose);

export const VEHICLE_MACHINERY_FINANCING_TYPES: InquiryFinancingTypeValue[] = [InquiryFinancingTypeValue.HirePurchase, InquiryFinancingTypeValue.InvestmentLoan, InquiryFinancingTypeValue.Leasing];
