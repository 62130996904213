import { RouteObject } from "react-router-dom";

import { ErrorPage } from "@/components/errorPage";
import { handleDynamicImportError } from "@/helpers/errorHelpers";
import { LazyFunctionReturnType, RouteType } from "@/types";

import { companiesRoutes } from "./companiesRoutes";
import { formsRoutes } from "./formsRoutes";
import { tasksRoutes } from "./tasksRoutes";

export const routes: RouteObject[] = [
  {
    path: "/",
    lazy: async (): Promise<LazyFunctionReturnType> => {
      try {
        const Layout = (await import("../features")).Layout;
        return {
          element: <Layout />,
          handle: {
            type: RouteType.Layout,
            publicRoute: true,
          },
        };
      } catch (error) {
        handleDynamicImportError(error);

        return {
          element: <ErrorPage />,
        };
      }
    },
    children: [
      {
        index: true,
        lazy: async (): Promise<LazyFunctionReturnType> => {
          try {
            const Dashboard = (await import("../features")).StartPage;
            return {
              element: <Dashboard />,
              handle: {
                type: RouteType.StartPage,
              },
            };
          } catch (error) {
            handleDynamicImportError(error);

            return {
              element: <ErrorPage />,
            };
          }
        },
      },
      {
        path: "sign-in/*",
        lazy: async (): Promise<LazyFunctionReturnType> => {
          try {
            const SignIn = (await import("../features")).SignIn;
            return {
              element: <SignIn />,
              handle: {
                type: RouteType.SignIn,
                publicRoute: true,
              },
            };
          } catch (error) {
            handleDynamicImportError(error);

            return {
              element: <ErrorPage />,
            };
          }
        },
      },
      {
        path: "sign-up/*",
        lazy: async (): Promise<LazyFunctionReturnType> => {
          try {
            const SignUp = (await import("../features")).SignUp;
            return {
              element: <SignUp />,
              handle: {
                type: RouteType.SignUp,
                publicRoute: true,
              },
            };
          } catch (error) {
            handleDynamicImportError(error);

            return {
              element: <ErrorPage />,
            };
          }
        },
      },
      ...companiesRoutes,
      ...formsRoutes,
      ...tasksRoutes,
      {
        path: "*",
        lazy: async (): Promise<LazyFunctionReturnType> => {
          const NotFound = (await import("../features")).NotFoundPage;
          return {
            element: <NotFound />,
            handle: {
              type: RouteType.NotFound,
            },
          };
        },
      },
    ],
  },
];
