import { Alert, List, ListItem, ListItemButton, ListSubheader } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./translations";

import { useCompanies_getRecentlyViewedCompanyProfilesQuery } from "@/api/companyApi";
import { LoadingList } from "@/components";

type RecentlyViewedCompanyProfilesSearchDialogListProps = {
  onItemOpenClick: () => void;
};

export const RecentlyViewedCompanyProfilesSearchDialogList = ({
  onItemOpenClick,
}: RecentlyViewedCompanyProfilesSearchDialogListProps): JSX.Element => {
  const { t } = useTranslation("RecentlyViewedCompanyProfilesSearchDialogList");

  const {
    data: recentlyViewedCompanyProfiles,
    isLoading: isRecentlyViewedCompanyProfilesLoading,
    isFetching: isRecentlyViewedCompanyProfilesFetching,
    isError: isRecentlyViewedCompanyProfilesError,
  } = useCompanies_getRecentlyViewedCompanyProfilesQuery();

  const isLoading = isRecentlyViewedCompanyProfilesLoading || isRecentlyViewedCompanyProfilesFetching;

  if (isRecentlyViewedCompanyProfilesError) {
    return (
      <Alert sx={{ my: 1 }} color="error">
        {t("loadingError")}
      </Alert>
    );
  }

  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      component="nav"
      subheader={<ListSubheader component="div">{t("title")}</ListSubheader>}
      disablePadding
    >
      {isLoading && !isRecentlyViewedCompanyProfilesError && <LoadingList />}
      {recentlyViewedCompanyProfiles?.data.slice(0, 4).map((recentlyViewedCompanyProfile) => (
        <ListItem disablePadding>
          <ListItemButton
            key={recentlyViewedCompanyProfile.companyProfileId}
            component={Link}
            to={`companies/${recentlyViewedCompanyProfile.companyProfileId}`}
            onClick={onItemOpenClick}
          >
            {recentlyViewedCompanyProfile.name}
          </ListItemButton>
        </ListItem>
      ))}
      <ListItem disablePadding component={Link} to="companies" onClick={onItemOpenClick}>
        <ListItemButton>{t("showAll")}</ListItemButton>
      </ListItem>
    </List>
  );
};
