import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./translations";

export const ErrorPage = (): JSX.Element => {
  const { t } = useTranslation("ErrorPage");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Typography sx={{ fontWeight: "bold", mb: 2 }} variant="body1">
        {t("errorMessage")}
      </Typography>
      <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
        {t("buttonText")}
      </Button>
    </Box>
  );
};
