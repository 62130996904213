import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

export type ContactCommunicationInformationsListItemProps = {
  icon: ReactElement;
  value: string;
  label: string;
  link?: string;
};

export const ContactCommunicationInformationsListItem = ({
  icon,
  label,
  value,
  link,
}: ContactCommunicationInformationsListItemProps): JSX.Element => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        sx={(theme) => ({
          display: "flex",
          alignItems: "flex-start",
          border: "1px solid white",
          borderRadius: "5px",
          px: 1,
          py: 0.5,
          "&:hover": { border: `1px solid ${theme.palette.primary.main}`, backgroundColor: "white" },
        })}
        {...(link && { component: Link, to: link })}
      >
        <ListItemIcon sx={{ minWidth: "40px", mt: 0.5 }}>{icon}</ListItemIcon>
        <ListItemText
          sx={{ my: 0 }}
          primary={
            <Typography sx={{ wordBreak: "break-word" }} variant="body1">
              {value}
            </Typography>
          }
          secondary={<Typography variant="body2">{label}</Typography>}
        />
      </ListItemButton>
    </ListItem>
  );
};
