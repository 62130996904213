import { IInquiryResponse, InquiryPurposeValue, PatchFinancingRequestRequest } from "@capetec/inquiries-api-client";
import { FinancingRequest, PurposeOfUse } from "../types";
import { isFactoringFinancing, isRealEstateAndBusinessFinancing, isVehicleAndMachineryFinancing } from "./constants";
import { DateTime } from "luxon";
import { TurnoverClassValue } from "@capetec/companies-api-client";

// #region map front-end data to back-end data
export const getPatchFinancingRequestRequestForSchemaData = (inquiryPurposeValue: InquiryPurposeValue, data: FinancingRequest): PatchFinancingRequestRequest | undefined => {
  if (isRealEstateAndBusinessFinancing(inquiryPurposeValue))
    return getPatchFinancingRequestRequestRealEstateAndBusinessFinancing(data);

  if (isVehicleAndMachineryFinancing(inquiryPurposeValue))
    return getPatchFinancingRequestRequestVehicleAndMachineryFinancing(data);

  if (isFactoringFinancing(inquiryPurposeValue))
    return getPatchFinancingRequestRequestFactoringFinancing(data);
}

const getPatchFinancingRequestRequestRealEstateAndBusinessFinancing = (data: FinancingRequest): PatchFinancingRequestRequest | undefined => {
  const { financialSolutions } = data;
  const { financingType, loanType, fixedInterestPeriodInMonths, interestRateType, requiredByDate } = financialSolutions || {};

  if (!financingType || !loanType || interestRateType == null || !requiredByDate) return;

  return new PatchFinancingRequestRequest({
    financingType,
    loanType: loanType.value,
    fixedInterestPeriodInMonths,
    fixedInterestRate: interestRateType,
    requestedPaymentDate: requiredByDate,
  })
}

const getPatchFinancingRequestRequestVehicleAndMachineryFinancing = (data: FinancingRequest): PatchFinancingRequestRequest | undefined => {
  const { vehicleAndMachinery } = data;
  const { financingType, downPayment, termInMonths, amortisationType, residualValue, requiredByDate } = vehicleAndMachinery || {};
  if (!financingType || downPayment == null || termInMonths == null || !amortisationType) return;
  return new PatchFinancingRequestRequest({
    financingType,
    advancePayment: downPayment,
    termInMonths,
    amortisationTypeValue: amortisationType.value,
    residualValue,
    requestedPaymentDate: requiredByDate,
  });
};

const getPatchFinancingRequestRequestFactoringFinancing = (data: FinancingRequest): PatchFinancingRequestRequest | undefined => {
  const { factoring } = data;
  const { financingType, foreignShare, largestCustomerShare, averagePaymentTermInDays, goodsCreditInsurance,
    activeCustomers, factoringBillsRange, alreadyUsingFactoring, requiredByDate, turnoverClass } = factoring || {};
  if (!financingType || foreignShare == null || largestCustomerShare == null ||
    averagePaymentTermInDays == null || goodsCreditInsurance == null || activeCustomers == null ||
    factoringBillsRange == null || alreadyUsingFactoring == null || requiredByDate == null || !turnoverClass) return;

  return new PatchFinancingRequestRequest({
    financingType,
    factoringForeignShare: foreignShare,
    factoringTurnoverShareWithLargestCustomer: largestCustomerShare,
    factoringAveragePaymentPeriodInDays: averagePaymentTermInDays,
    factoringCommercialCreditInsurance: goodsCreditInsurance,
    factoringActiveCustomers: activeCustomers,
    factoringBillsRange: factoringBillsRange.value,
    factoringAlreadyInUse: alreadyUsingFactoring,
    requestedPaymentDate: requiredByDate,
    turnoverClassId: turnoverClass.id,
  });
}
// #endregion map front-end data to back-end data

//#region map back-end data to front-end data
export const mapInquiryToFinancingRequest = (inquiry: IInquiryResponse | undefined, purposeOfUse: PurposeOfUse | undefined): FinancingRequest | undefined => {
  if (!inquiry || !purposeOfUse?.active) return;
  if (isRealEstateAndBusinessFinancing(purposeOfUse.active))
    return mapInquiryToRealEstateAndBusinessFinancing(inquiry, purposeOfUse.active);

  if (isVehicleAndMachineryFinancing(purposeOfUse.active))
    return mapInquiryToVehicleAndMachineryFinancing(inquiry, purposeOfUse.active);

  if (isFactoringFinancing(purposeOfUse.active))
    return mapInquiryToFactoringFinancing(inquiry, purposeOfUse.active);
}

const mapInquiryToRealEstateAndBusinessFinancing = (inquiry: IInquiryResponse, active: InquiryPurposeValue
): FinancingRequest | undefined => ({
  active,
  financialSolutions: {
    financingType: inquiry.acceptableFinancingTypes?.[0]?.value,
    loanType: inquiry.loanType,
    fixedInterestPeriodInMonths: inquiry.fixedInterestPeriodInMonths,
    interestRateType: inquiry.fixedInterestRate,
    requiredByDate: inquiry.requestedPaymentDate,
  }
});

const mapInquiryToVehicleAndMachineryFinancing = (inquiry: IInquiryResponse, active: InquiryPurposeValue): FinancingRequest | undefined => ({
  active,
  vehicleAndMachinery: {
    financingType: inquiry.acceptableFinancingTypes?.[0]?.value,
    downPayment: inquiry.advancePayment,
    termInMonths: inquiry.termInMonths,
    ...(inquiry.amortisationTypeLabel != null && inquiry.amortisationTypeValue != null) && {
      amortisationType: {
        id: -1,
        createdDateTime: DateTime.now(),
        name: inquiry.amortisationTypeLabel,
        value: inquiry.amortisationTypeValue,
      },
    },
    residualValue: inquiry.residualValue,
    requiredByDate: inquiry.requestedPaymentDate,
  }
});

const mapInquiryToFactoringFinancing = (inquiry: IInquiryResponse, active: InquiryPurposeValue): FinancingRequest | undefined => ({
  active,
  factoring: {
    financingType: inquiry.acceptableFinancingTypes?.[0]?.value,
    foreignShare: inquiry.factoringForeignShare,
    largestCustomerShare: inquiry.factoringTurnoverShareWithLargestCustomer,
    averagePaymentTermInDays: inquiry.factoringAveragePaymentPeriodInDays,
    goodsCreditInsurance: inquiry.factoringCommercialCreditInsurance,
    activeCustomers: inquiry.factoringActiveCustomers,
    ...inquiry.factoringBillsRange && {
      factoringBillsRange: {
        id: inquiry.factoringBillsRange.id,
        createdDateTime: inquiry.factoringBillsRange.createdDateTime,
        name: inquiry.factoringBillsRange.name,
        value: inquiry.factoringBillsRange.value,
        amountFrom: inquiry.factoringBillsRange.amountFrom,
        amountTo: inquiry.factoringBillsRange.amountTo,
      },
    },
    alreadyUsingFactoring: inquiry.factoringAlreadyInUse,
    requiredByDate: inquiry.requestedPaymentDate,
    ...inquiry.turnoverClassId != null && {
      turnoverClass: {
        id: inquiry.turnoverClassId,
        name: inquiry.turnoverClassId?.toString() || "",
        value: TurnoverClassValue.From0To10Thousand,
      }
    }
  }
});

//#endregion map back-end data to front-end data
