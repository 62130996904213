import { Theme, createTheme } from "@mui/material";
import { LinkProps } from "@mui/material/Link";

import { LinkBehavior } from "../behaviors";
import { scrollBarWidth } from "../constants";

const themeColors = {
  primary: {
    light: "#23ADFA",
    main: "#009EE3",
    darker: "#008ECC",
    dark: "#008ECC",
  },
  secondary: {
    main: "#4EC8FF",
    dark: "#00B7FF",
  },
  background: {
    default: "#F9FAFB",
    paper: "#fff",
  },
  success: {
    light: "#55f549",
    main: "#1cb811",
    dark: "#0e9404",
  },
};

const gradients = {
  primary: `linear-gradient(to right top, ${themeColors.primary.dark}, ${themeColors.primary.darker}, ${themeColors.primary.main}, ${themeColors.secondary.dark}, ${themeColors.secondary.main})`,
};

export const LightTheme = (...args: object[]): Theme =>
  createTheme(
    {
      breakpoints: {
        keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
        values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536, xxl: 1900 },
      },
      palette: {
        mode: "light",
        primary: themeColors.primary,
        secondary: themeColors.secondary,
        success: {
          light: themeColors.success.light,
          main: themeColors.success.main,
          dark: themeColors.success.dark,
          contrastText: "#fff",
        },
        background: {
          default: themeColors.background.default,
          paper: themeColors.background.paper,
        },
        text: {
          primary: "#444",
        },
      },
      sidebar: {
        background: gradients.primary,
        borderRadius: "0px 5px 5px 0px",
        textColor: "white",
        secondaryTextColor: "rgba(255, 255, 255, 0.8)",
        drawerWidth: 180,
        drawerWidthClosed: 90,
      },
      typography: {
        h1: {
          color: "#444",
        },
        h2: {
          color: "#444",
        },
        h3: {
          color: "#444",
        },
        h4: {
          color: "#444",
        },
        h5: {
          color: "#444",
        },
        h6: {
          color: "#444",
        },
        body1: {
          color: "#444",
          fontSize: 14,
        },
        body2: {
          fontSize: 12,
          color: "#aaa",
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            html: {
              /* width */
              "*::-webkit-scrollbar": {
                width: scrollBarWidth,
              },
              /* Track */
              "*::-webkit-scrollbar-track": {
                background: "transparent",
              },
              /* Handle */
              "*::-webkit-scrollbar-thumb": {
                height: "40px",
                border: `5px solid rgba(0, 0, 0, 0)`,
                backgroundClip: "padding-box",
                borderRadius: "12px",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              },
              /* Handle on hover */
              "*::-webkit-scrollbar-thumb:hover": {
                border: `2px solid rgba(0, 0, 0, 0)`,
                backgroundClip: "padding-box",
                borderRadius: "12px",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              },
              "*::-webkit-scrollbar-corner": {
                background: "transparent",
              },
            },
            body: {
              backgroundColor: "rgb(249,250,251,255)",
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: "#999",
            },
            shrink: {
              marginTop: "-5px",
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                backgroundColor: "#f1f5f9",
                "& fieldset": {
                  borderColor: themeColors.primary.main,
                  borderWidth: "0px",
                },
                "&.Mui-focused": {
                  backgroundColor: "white",
                },
                "&:hover:not(.Mui-focused)": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: themeColors.primary.main,
                    borderWidth: "1.5px",
                  },
                },
              },
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            root: {
              "&.MuiOutlinedInput-root": {
                backgroundColor: "#f1f5f9",
                borderRadius: "10px",
                "& fieldset": {
                  borderRadius: "10px",
                  borderWidth: "0px",
                },
                "&:hover fieldset": {
                  // borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: themeColors.primary.main,
                },
                "&.Mui-focused": {
                  backgroundColor: "white",
                },
              },
            },
          },
        },
        MuiLink: {
          defaultProps: {
            component: LinkBehavior,
          } as LinkProps,
        },
        MuiButtonBase: {
          defaultProps: {
            LinkComponent: LinkBehavior,
          },
        },
        MuiChip: {
          variants: [
            {
              props: { variant: "primaryChip" },
              style: {
                height: "35px",
                borderRadius: "10px",
                border: `1px solid ${themeColors.primary.main}`,
                marginRight: "10px",
                fontWeight: "400",
                textDecoration: "none",
                textTransform: "none",
                color: themeColors.primary.main,
                backgroundColor: "#f1f5f9",
              },
            },
            {
              props: { variant: "secondaryChip" },
              style: {
                borderRadius: "10px",
                border: "1px solid #eee",
                textDecoration: "none",
                textTransform: "none",
                cursor: "pointer",
                color: "#888",
                backgroundColor: "#f1f5f9",
                "&:hover": {
                  border: `1px solid ${themeColors.primary.main}`,
                },
              },
            },
          ],
        },
        MuiButton: {
          styleOverrides: {
            root: {
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            },
          },
          variants: [
            {
              props: { variant: "primaryButton" },
              style: {
                padding: "5px 8px",
                borderRadius: "10px",
                border: `1px solid ${themeColors.primary.main}`,
                marginRight: "10px",
                textDecoration: "none",
                textTransform: "none",
                color: themeColors.primary.main,
                backgroundColor: "#f1f5f9",
                "&:hover": {
                  backgroundColor: "#f1f5f9",
                },
              },
            },
            {
              props: { variant: "greenPrimaryButton" },
              style: {
                padding: "5px 8px",
                borderRadius: "10px",
                border: `1px solid ${themeColors.success.main}`,
                marginRight: "10px",
                textDecoration: "none",
                textTransform: "none",
                color: themeColors.success.main,
                backgroundColor: "#f1f9f1",
                "&:hover": {
                  backgroundColor: "#f1f9f1",
                },
              },
            },
          ],
        },
        MuiCard: {
          variants: [
            {
              props: { variant: "companyProfileCard" },
              style: {
                "&.MuiCard-root": {
                  filter: "drop-shadow(-5px 7px 5px rgba(0, 0, 0, 0.05))",
                  borderRadius: "10px",
                },
                "& .MuiCardHeader-root": {
                  background: "white",
                  "& .MuiCardHeader-title,.MuiCardHeader-subheader,.MuiCardHeader-action,.MuiIconButton-root": {
                    color: "#555",
                    fontSize: "14px",
                    fontWeight: "bold",
                  },
                },
              },
            },
            {
              props: { variant: "transparentCompanyProfileCard" },
              style: {
                "&.MuiCard-root": {
                  background: "transparent",
                  boxShadow: "none",
                },
                "& .MuiCardHeader-root": {
                  background: "transparent",
                  "& .MuiCardHeader-title,.MuiCardHeader-subheader,.MuiCardHeader-action,.MuiIconButton-root": {
                    color: "#555",
                    fontSize: "14px",
                    fontWeight: "bold",
                  },
                },
              },
            },
            {
              props: { variant: "primaryCard" },
              style: {
                "&.MuiCard-root": {
                  borderRadius: "10px",
                  filter: "drop-shadow(-5px 7px 5px rgba(0, 0, 0, 0.05))",
                },
              },
            },
            {
              props: { variant: "greyCardSelect" },
              style: {
                "&.MuiCard-root": {
                  border: "1px solid white",
                  borderRadius: "10px",
                  marginRight: "15px",
                  cursor: "pointer",
                  textDecoration: "none",
                  transition: "border 0.3s",
                  backgroundColor: "#F1F5F9",
                  "&:hover": {
                    border: `1px solid ${themeColors.primary.main}`,
                  },
                },
              },
            },
            {
              props: { variant: "calculatorButton" },
              style: {
                "&.MuiCard-root": {
                  width: "150px",
                  height: "130px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid white",
                  borderRadius: "10px",
                  marginRight: "15px",
                  cursor: "pointer",
                  textDecoration: "none",
                  transition: "border 0.3s",
                  backgroundColor: "#F1F5F9",
                  "&:hover": {
                    border: `1px solid ${themeColors.primary.main}`,
                  },
                },
              },
            },
            {
              props: { variant: "greyCard" },
              style: {
                "&.MuiCard-root": {
                  borderRadius: "10px",
                  backgroundColor: "#F1F5F9",
                },
              },
            },
          ],
        },
      },
    },
    ...args,
  );
