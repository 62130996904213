import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";

type SidebarItemProps = {
  selected: boolean;
  open: boolean;
  label: string;
  icon: JSX.Element;
  to: string;
  handleOnClick: () => void;
};

export const SidebarItem = ({ selected, open, label, icon, to, handleOnClick }: SidebarItemProps): JSX.Element => {
  return (
    <ListItem
      onClick={handleOnClick}
      disablePadding
      sx={(theme) => ({
        display: "flex",
        height: "60px",
        backgroundColor: selected ? "rgba(0, 0, 0, 0.2)" : "transparent",
        borderRadius: theme.sidebar.borderRadius,
      })}
    >
      <Box
        sx={(theme) => ({
          height: "100%",
          width: "3px",
          borderRadius: theme.sidebar.borderRadius,
          backgroundColor: selected ? "white" : "transparent",
        })}
      />
      <ListItemButton
        to={to}
        component={Link}
        sx={{
          minHeight: 60,
          pl: open ? 0 : 2,
        }}
      >
        <ListItemIcon
          sx={{
            justifyContent: "center",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              sx={(theme) => ({ color: selected ? theme.sidebar.textColor : theme.sidebar.secondaryTextColor })}
              variant="body1"
            >
              {label}
            </Typography>
          }
          sx={{
            opacity: open ? 1 : 0,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
