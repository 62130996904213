import { SupportedLanguage, SupportedLanguagesCode } from "./types";
import {
  enUS as enUSDatePicker,
  deDE as deDEDatePicker,
} from "@mui/x-date-pickers/locales";
import {
  enUS as enUSMuiLocalization,
  deDE as deDEMuiLocalization,
} from "@mui/material/locale";
import {
  enUS as enUSDataGrid,
  deDE as deDEDataGrid,
} from '@mui/x-data-grid/locales';
import { deDE, enUS } from "@clerk/localizations";

export const FALLBACK_LANGUAGE_CODE = SupportedLanguagesCode.deDE;
export const FALLBACK_SUPPORTED_LANGUAGE: Required<SupportedLanguage> = {
  code: SupportedLanguagesCode.deDE,
  name: "Deutsch",
  shortName: "DE",
  xDatePickersLocale:
    deDEDatePicker.components.MuiLocalizationProvider.defaultProps.localeText,
  muiLocalization: deDEMuiLocalization,
  dataGridLocalization: deDEDataGrid,
  clerkLocalization: deDE,
};

export const supportedLanguages: SupportedLanguage[] = [
  FALLBACK_SUPPORTED_LANGUAGE,
  {
    code: FALLBACK_LANGUAGE_CODE,
    name: "English",
    shortName: "EN",
    xDatePickersLocale:
      enUSDatePicker.components.MuiLocalizationProvider.defaultProps.localeText,
    muiLocalization: enUSMuiLocalization,
    dataGridLocalization: enUSDataGrid,
    clerkLocalization: enUS,
  },
];
