import { useMemo } from "react";
import { useRouteHandleMatches } from "./useRouteHandleMatches"
import { UIMatch } from "react-router-dom";
import { RouteHandle } from "@/types";

export const useCurrentRoute = (): UIMatch<unknown, RouteHandle> | undefined => {
  const routes = useRouteHandleMatches();
  const currentRoute = useMemo(() => routes.length > 0 ? routes[routes.length - 1] : undefined, [routes]);

  return currentRoute;
}
