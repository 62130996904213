import { createApi } from "@reduxjs/toolkit/query/react";
import { TagType } from "../enums";
import { companyApiBaseQuery } from "./companyApiBaseQuery";

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: companyApiBaseQuery({
    // Fill in your own server starting URL here
    baseUrl: window.REACT_APP_COMPANY_API,
  }),
  endpoints: () => ({}),
  tagTypes: Object.values(TagType),
});
