import { Box } from "@mui/material";

import { CalculatorsSubMenu } from "./calculatorsSubMenu";
import { InquirySubMenu } from "./inquirySubMenu";
import { SubMenu } from "./subMenu";

type HeaderProps = {
  open: boolean;
  isCompanyPage: boolean;
  isInquiryPage: boolean;
  isCalculatorPage: boolean;
};

export const Header = ({ isCalculatorPage, isInquiryPage, isCompanyPage, open }: HeaderProps): JSX.Element => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
      <Box
        sx={(theme) => ({
          ml: `calc(${open ? "150px" : "100px"} + ${theme.spacing(6)})`,
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        })}
      >
        {isCompanyPage && <SubMenu />}
        {isInquiryPage && <InquirySubMenu />}
        {isCalculatorPage && <CalculatorsSubMenu />}
      </Box>
    </Box>
  );
};
