import { CompanySearchResultResponse } from "@capetec/companies-api-client";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { Alert } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./translations";

import { PaginationResponse } from "@/api/companyApi/types";
import { LoadingList } from "@/components";
import { ListItemGroupHeaderButton } from "@/components/listItemGroupHeaderButton";

export type AvailableCompaniesResultListProps = {
  companySuggestionsPaginationData?: PaginationResponse<CompanySearchResultResponse>;
  onCompanySuggestionClick: (companySuggestion: CompanySearchResultResponse) => void;
  isCompanySuggestionsLoading: boolean;
  isCompanySuggestionsError: boolean;
};

export const AvailableCompaniesResultList = ({
  companySuggestionsPaginationData,
  onCompanySuggestionClick,
  isCompanySuggestionsLoading,
  isCompanySuggestionsError,
}: AvailableCompaniesResultListProps): JSX.Element => {
  const [availableCompaniesResultOpen, setAvailableCompaniesResultOpen] = useState(true);
  const { t } = useTranslation("AvailableCompaniesResultList");

  const handleToggleGroupOpenClick = (): void => {
    setAvailableCompaniesResultOpen(!availableCompaniesResultOpen);
  };

  return (
    <>
      <ListItemGroupHeaderButton
        icon={<AddBusinessIcon />}
        isLoading={isCompanySuggestionsLoading}
        isOpen={availableCompaniesResultOpen}
        onClick={handleToggleGroupOpenClick}
        title={t("createNewCompany")}
      />
      <Collapse in={availableCompaniesResultOpen} timeout="auto" unmountOnExit>
        {isCompanySuggestionsError ? (
          <Alert sx={{ my: 1 }} severity="error">
            {t("loadingError")}
          </Alert>
        ) : (
          <List component="div" disablePadding color="primary">
            {!companySuggestionsPaginationData || isCompanySuggestionsLoading ? (
              <LoadingList itemAmount={10} height="30px" />
            ) : (
              companySuggestionsPaginationData.data.map((companySuggestion) => (
                <ListItemButton
                  sx={{ pl: 4 }}
                  key={companySuggestion.name}
                  onClick={() => onCompanySuggestionClick(companySuggestion)}
                >
                  <ListItemText primary={companySuggestion.name ?? "-"} />
                </ListItemButton>
              ))
            )}
          </List>
        )}
      </Collapse>
    </>
  );
};
