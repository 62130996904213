import { IErrorDto, IMetaData, V1DocumentsApiClient } from "@capetec/documents-api-client";
import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios from "axios";
import type { Method, RawAxiosRequestHeaders } from "axios";
import { isArray } from "lodash";
import { stringify } from "qs";

import { documentsClient } from "@/services/documentsApi";

import { deserializeDatesInObject } from "../../helpers/dateHelpers";
import { handleApiErrorWithNotFoundHandling } from "./helpers";
import { DocumentsApiResponse } from "./types";

const postHeaders: RawAxiosRequestHeaders = {
  "Content-Type": "application/json",
};

const getHeaders = (method: Method): RawAxiosRequestHeaders | undefined => {
  switch (method) {
    case "post":
      return postHeaders;
    default:
      return undefined;
  }
};

export const documentApiBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" },
  ): BaseQueryFn<
    {
      url?: string;
      method?: Method;
      data?: unknown;
      params?: unknown;
      apiMethod?: (client: V1DocumentsApiClient) => Promise<DocumentsApiResponse<unknown>>;
    },
    unknown,
    IErrorDto[],
    NonNullable<unknown>,
    IMetaData
  > =>
  async ({ url, method, data, params, apiMethod }) => {
    if (apiMethod != null) {
      try {
        const response = await apiMethod(documentsClient);
        if (response.data) {
          return {
            data: response.data,
            meta: response.meta,
          };
        }

        return {
          error: response.errors,
          meta: response.meta,
        };
      } catch (error) {
        return handleApiErrorWithNotFoundHandling(error);
      }
    }
    if (url != null && method != null) {
      try {
        const headers = getHeaders(method);
        const result = await axios({
          url: baseUrl + url,
          method,
          data,
          params,
          headers,
          paramsSerializer: (params) => stringify(params, { indices: false }),
        });
        return { data: deserializeDatesInObject(result.data) };
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.status === 404) {
            return {
              data: undefined,
            };
          }

          return {
            error: [
              {
                status: error.status || -1,
                title: error.message,
                detail: error.response?.statusText,
              },
            ],
          };
        }

        if (error instanceof Error) {
          return {
            error: [
              {
                title: error.message,
                detail: error.stack,
                status: 400,
              },
            ],
          };
        }

        if (isArray(error) && error.length >= 1 && error[0] === 404) {
          return {
            data: undefined,
          };
        }

        return {
          error: [
            {
              title: "Unknown error",
              detail: "An unknown error occurred.",
              status: 400,
            },
          ],
        };
      }
    }

    return {
      error: [
        {
          title: "Invalid query",
          detail: "Neither apiMethod nor url and method were provided.",
          status: 404,
        },
      ],
    };
  };
