import { useMemo } from "react";
import { UIMatch, useMatches } from "react-router-dom";
import { isRouteHandle, RouteHandle } from "../types";

export const useRouteHandleMatches = (): UIMatch<unknown, RouteHandle>[] => {
  const matches = useMatches();

  const returnMatches = useMemo(
    () => matches.filter((match): match is UIMatch<unknown, RouteHandle> => isRouteHandle(match.handle)),
    [matches],
  );

  return returnMatches;
};
