import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { FinancingRequest, PurposeOfUse } from "./types";
import { IInquiryResponse } from "@capetec/inquiries-api-client";
import { RootState } from "@/store/store";
import { mapInquiryToPurposeOfUse } from "./purposeOfUse/purposeOfUseHelper";
import { mapInquiryToFinancingRequest } from "./financingRequests/financingRequestsHelper";

export interface CompanyWizardSliceState {
  purposeOfUse?: PurposeOfUse;
  financingRequest?: FinancingRequest;
  inquiry?: IInquiryResponse;
}

const initialState: CompanyWizardSliceState = {
  purposeOfUse: undefined,
  financingRequest: undefined,
  inquiry: undefined,
};

export const companyWizardSlice = createSlice({
  name: "companyWizard",
  initialState,
  reducers: {
    setPurposeOfUse: (state, action: PayloadAction<PurposeOfUse>) => {
      state.purposeOfUse = action.payload;
    },
    setFinancingRequest: (state, action: PayloadAction<FinancingRequest>) => {
      state.financingRequest = action.payload;
    },
    setInquiry: (state, action: PayloadAction<IInquiryResponse | undefined>) => {
      const { payload } = action;
      state.inquiry = payload;
      const purposeOfUse = mapInquiryToPurposeOfUse(payload);
      state.purposeOfUse = purposeOfUse;
      state.financingRequest = mapInquiryToFinancingRequest(payload, purposeOfUse);
    },
    resetWizard: (state) => {
      state.purposeOfUse = undefined;
      state.financingRequest = undefined;
      state.inquiry = undefined;
    }
  },
});

export const { setPurposeOfUse, setFinancingRequest, setInquiry, resetWizard } = companyWizardSlice.actions;

export const CompanyWizardReducer = companyWizardSlice.reducer;

export const isInquirySetSelector = createSelector(
  [(state: RootState): IInquiryResponse | undefined => state.companyWizard.inquiry],
  (inquiry) => inquiry != null
)
