import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import "../translations";
import { HeaderSearchBarDialog } from "./headerSearchBarDialog";

export const HeaderSearchBar = (): JSX.Element => {
  const { t } = useTranslation("SearchBar");
  const outBoxRef = useRef<HTMLDivElement | null>(null);
  const btnToggleSearchResultsRef = useRef<HTMLButtonElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const [headerSearchBarDialogOpen, setHeaderSearchBarDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    const currentOutBoxRef = outBoxRef.current;
    if (!currentOutBoxRef) return;

    const documentClick = (e: MouseEvent): void => {
      if (!popoverOpen) return;
      const target = e.target as Node;
      if (currentOutBoxRef.contains(target) || btnToggleSearchResultsRef.current?.contains(target)) return;
      setPopoverOpen(false);
    };
    document.addEventListener("click", documentClick);

    return (): void => {
      document.removeEventListener("click", documentClick);
    };
  }, [popoverOpen]);

  const handleHeaderSearchBarDialogClose = (): void => {
    setHeaderSearchBarDialogOpen(false);
  };

  const handleOpenHeaderSearchBarDialogClick = (): void => {
    setHeaderSearchBarDialogOpen(true);
  };

  return (
    <>
      <Button startIcon={<SearchIcon />} variant="text" color="primary" onClick={handleOpenHeaderSearchBarDialogClick}>
        {t("placeholder")}
      </Button>
      <HeaderSearchBarDialog open={headerSearchBarDialogOpen} onClose={handleHeaderSearchBarDialogClose} />
    </>
  );
};
