import { RouteObject } from "react-router";

import { ErrorPage } from "@/components/errorPage";
import { handleDynamicImportError } from "@/helpers/errorHelpers";
import { LazyFunctionReturnType, RouteType } from "@/types/routes";

export const companiesRoutes: RouteObject[] = [
  {
    path: "companies",
    handle: {
      type: RouteType.Companies,
    },
    children: [
      {
        index: true,
        async lazy(): Promise<LazyFunctionReturnType> {
          try {
            const Companies = (await import("../features/companies")).Companies;
            return {
              element: <Companies />,
            };
          } catch (error) {
            handleDynamicImportError(error);

            return {
              element: <ErrorPage />,
            };
          }
        },
      },
      {
        path: ":companyId",
        async lazy(): Promise<LazyFunctionReturnType> {
          try {
            const CompanyIndex = (await import("../features/company")).CompanyIndex;
            return {
              element: <CompanyIndex />,
              handle: {
                type: RouteType.CompanyIndex,
              },
            };
          } catch (error) {
            handleDynamicImportError(error);

            return {
              element: <ErrorPage />,
            };
          }
        },
        children: [
          {
            index: true,
            async lazy(): Promise<LazyFunctionReturnType> {
              try {
                const Company = (await import("../features/company")).CompanyInformation;
                return {
                  element: <Company />,
                  handle: {
                    type: RouteType.CompanyInformation,
                  },
                };
              } catch (error) {
                handleDynamicImportError(error);

                return {
                  element: <ErrorPage />,
                };
              }
            },
          },
          {
            path: "details",
            async lazy(): Promise<LazyFunctionReturnType> {
              try {
                const CompanyDetailsView = (await import("../features/company")).CompanyDetailsView;
                return {
                  element: <CompanyDetailsView />,
                  handle: {
                    type: RouteType.CompanyDetailsView,
                  },
                };
              } catch (error) {
                handleDynamicImportError(error);

                return {
                  element: <ErrorPage />,
                };
              }
            },
          },
          {
            path: "contacts",
            async lazy(): Promise<LazyFunctionReturnType> {
              try {
                const CompanyContactsView = (await import("../features/company/companyDetailViews"))
                  .CompanyContactsView;
                return {
                  element: <CompanyContactsView />,
                  handle: {
                    type: RouteType.CompanyContactsView,
                  },
                };
              } catch (error) {
                handleDynamicImportError(error);

                return {
                  element: <ErrorPage />,
                };
              }
            },
          },
          {
            path: "ratings",
            async lazy(): Promise<LazyFunctionReturnType> {
              try {
                const CompanyRatingsView = (await import("../features/company/companyDetailViews")).CompanyRatingsView;
                return {
                  element: <CompanyRatingsView />,
                  handle: {
                    type: RouteType.CompanyRatingsView,
                  },
                };
              } catch (error) {
                handleDynamicImportError(error);

                return {
                  element: <ErrorPage />,
                };
              }
            },
          },
          {
            path: "calculators",
            async lazy(): Promise<LazyFunctionReturnType> {
              try {
                const CompanyCalculators = (await import("../features/company")).CompanyCalculators;
                return {
                  element: <CompanyCalculators />,
                  handle: {
                    type: RouteType.CompanyCalculators,
                  },
                };
              } catch (error) {
                handleDynamicImportError(error);

                return {
                  element: <ErrorPage />,
                };
              }
            },
          },
          {
            path: "inquiries",
            handle: {
              type: RouteType.CompanyInquiries,
            },
            children: [
              {
                index: true,
                async lazy(): Promise<LazyFunctionReturnType> {
                  try {
                    const CompanyInquiries = (await import("../features/company")).CompanyInquiries;
                    return {
                      element: <CompanyInquiries />,
                    };
                  } catch (error) {
                    handleDynamicImportError(error);

                    return {
                      element: <ErrorPage />,
                    };
                  }
                },
              },
              {
                path: ":inquiryId",
                async lazy(): Promise<LazyFunctionReturnType> {
                  try {
                    const Inquiry = (await import("../features/company")).CompanyInquiries;
                    return {
                      element: <Inquiry />,
                      handle: {
                        type: RouteType.CompanyInquiry,
                      },
                    };
                  } catch (error) {
                    handleDynamicImportError(error);

                    return {
                      element: <ErrorPage />,
                    };
                  }
                },
              },
            ],
          },
          {
            path: "wizard/:inquiryId",
            async lazy(): Promise<LazyFunctionReturnType> {
              try {
                const CompanyWizard = (await import("../features/company")).CompanyWizard;
                return {
                  element: <CompanyWizard />,
                  handle: {
                    type: RouteType.CompanyWizard,
                  },
                };
              } catch (error) {
                handleDynamicImportError(error);

                return {
                  element: <ErrorPage />,
                };
              }
            },
          },
        ],
      },
    ],
  },
];
