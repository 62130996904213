import { useMemo } from "react";
import { useParams } from "react-router-dom";

/**
 * Response object for the useCompanyIdFromParams hook.
 * @property companyId - The company id.
 * @property companyIdIsAvailable - A boolean indicating if the company id is available. If not available, the company id will be -1.
 */
export type CompanyIdFromParamsResponse = {
  companyId: number;
  companyIdIsAvailable: boolean;
}

/**
 * Hook to get the company id from the URL params.
 * @returns The company id and a boolean indicating if the company id is available. If not available, the company id will be -1.
 */
export const useCompanyIdFromParams = (): CompanyIdFromParamsResponse => {
  const { companyId: companyIdParam } = useParams();

  const companyIdResponse: CompanyIdFromParamsResponse = useMemo(() => {
    if (companyIdParam == null) return {
      companyId: -1,
      companyIdIsAvailable: false,
    };

    const companyIdNumber = Number(companyIdParam);
    return {
      companyId: companyIdNumber,
      companyIdIsAvailable: !isNaN(companyIdNumber),
    }
  }, [companyIdParam]);

  return companyIdResponse;
};
