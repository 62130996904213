//#region react-router-dom types
import { RouteObject } from "react-router-dom";

import { RequireOne } from "./general";

// some types not exported by react-router-dom :-(
export type ImmutableRouteKey = "lazy" | "caseSensitive" | "path" | "id" | "index" | "children";

export type LazyFunctionReturnType = RequireOne<Omit<RouteObject, ImmutableRouteKey>>;
//#endregion react-router-dom types

export enum RouteType {
  Layout = "Layout",
  StartPage = "StartPage",
  NotFound = "NotFound",
  Companies = "Companies",
  CompanyIndex = "CompanyIndex",
  CompanyInformation = "CompanyInformation",
  CompanyCalculators = "CompanyCalculators",
  CompanyInquiries = "CompanyInquiries",
  CompanyInquiry = "CompanyInquiry",
  CompanyDetailsView = "CompanyDetailsView",
  CompanyContactsView = "CompanyContactsView",
  CompanyRatingsView = "CompanyRatingsView",
  CompanyWizard = "CompanyWizard",
  SignIn = "SignIn",
  SignUp = "SignUp",
  Forms = "Forms",
  Tasks = "Tasks",
}

export type RouteHandle = {
  type: RouteType;
  publicRoute?: boolean;
};

export const isRouteHandle = <T>(obj: T | RouteHandle): obj is RouteHandle => (obj as RouteHandle)?.type != null;

export const routeTypesWithoutTranslation = [
  RouteType.Layout,
  RouteType.CompanyIndex,
  RouteType.SignIn,
  RouteType.SignUp,
] as const;

type RouteTypesWithoutTranslationTypes = (typeof routeTypesWithoutTranslation)[number];
export type RoutesExcludingWithoutTranslation = Exclude<RouteType, RouteTypesWithoutTranslationTypes>;

export const isRouteTypeWithTranslation = (type: RouteType): type is RoutesExcludingWithoutTranslation =>
  !(routeTypesWithoutTranslation as ReadonlyArray<RouteType>).includes(type);
