import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getLanguage } from "@/i18n";

type LocalizationProviderProps = {
  children?: React.ReactNode;
};

export const LocalizationProvider = ({ children }: LocalizationProviderProps): JSX.Element => {
  const { i18n } = useTranslation();
  const defaultLanguage = useMemo(() => getLanguage(i18n.language), [i18n.language]);

  return (
    <MuiLocalizationProvider
      dateAdapter={AdapterLuxon}
      // Define the date locale to have the right format `day.month.year`.
      adapterLocale={defaultLanguage.code.toString()}
      // Define the translations to have the right placeholders (e.g. `JJJJ` for the year).
      localeText={defaultLanguage.xDatePickersLocale}
    >
      {children}
    </MuiLocalizationProvider>
  );
};
