import { ICompanyProfileResponse } from "@capetec/companies-api-client";
import { TagDescription } from "@reduxjs/toolkit/query";

import { TagType } from "@/api/enums";

import { PaginationResponse } from "../types";

export const provideTagsForPaginationResponseICompanyProfileResponse = (
  result: PaginationResponse<ICompanyProfileResponse> | undefined,
): ReadonlyArray<TagDescription<TagType>> =>
  result?.data != null
    ? result.data.reduce(
        (prev, current: ICompanyProfileResponse) => {
          const result = [...prev];
          if (current != null) {
            result.push(...provideTagsForICompanyProfileResponse(current));
          }
          return result;
        },
        [] as ReadonlyArray<TagDescription<TagType>>,
      )
    : [TagType.CompanyApi_CompanyProfileDto];

export const provideTagsForICompanyProfileResponse = (
  result: ICompanyProfileResponse | undefined,
): ReadonlyArray<TagDescription<TagType>> => {
  if (result == null) return [TagType.CompanyApi_CompanyProfileDto];
  const tags = [{ type: TagType.CompanyApi_CompanyProfileDto, id: result.id }];
  if (result.countryOfOrigin && result.countryOfOriginId != null)
    tags.push({ type: TagType.CompanyApi_CompanyProfileStatusResponse, id: result.countryOfOriginId });
  if (result.economicSector && result.economicSectorId != null)
    tags.push({ type: TagType.CompanyApi_EconomicSectorResponse, id: result.economicSectorId });
  if (result.legalForm && result.legalFormId != null)
    tags.push({ type: TagType.CompanyApi_LegalFormResponse, id: result.legalFormId });
  if (result.mainAddress && result.mainAddressId != null)
    tags.push({ type: TagType.CompanyApi_AddressTypeResponse, id: result.mainAddressId });
  if (result.turnoverClass && result.annualNetProfitClassId != null)
    tags.push({ type: TagType.CompanyApi_AnnualNetProfitClassResponse, id: result.annualNetProfitClassId });
  if (result.turnoverClassNavigation && result.turnoverClassId != null)
    tags.push({ type: TagType.CompanyApi_TurnoverClassResponse, id: result.turnoverClassId });
  return tags;
};
