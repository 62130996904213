import { CompaniesApiResponse, PaginationResponse } from "../types";

export const transformCompaniesApiResponseToPaginationResponse = <T>(
  response: CompaniesApiResponse<T[]>,
): PaginationResponse<T> => {
  return {
    data: response.data || [],
    pagination: response.meta?.pagination,
  };
};

export const transformCompaniesApiResponseToDto = <T>(response: CompaniesApiResponse<T>): T | undefined =>
  response.data;

export function transformApiPaginationResponse<TData>(
  response: CompaniesApiResponse<TData[]>,
): CompaniesApiResponse<PaginationResponse<TData>> {
  if (response.errors) {
    return {
      errors: response.errors,
      meta: response.meta,
    };
  }

  const paginationData: PaginationResponse<TData> = {
    data: response.data ?? [],
    pagination: response.meta?.pagination,
  };

  return {
    data: paginationData,
    meta: response.meta,
  };
}
