import {
  CalculationAcceptRequest,
  CalculationAcceptResponse,
  CalculationCreateRequest,
  CalculationStatusResponse,
  CalculationTransactionResponse,
  PreCheckTransactionResponse,
} from "@capetec/products-api-client";

import { TagType } from "@/api/enums";

import { productApi } from "../productApi";
import { GetCalculationResultOfferStatusRequest, GetCalculationsRequest } from "../types";
import { GetPreCheckByCompanyProfileIdRequest, ProductsApiResponse } from "../types";

export const productsController = productApi.injectEndpoints({
  endpoints: (builder) => ({
    products_createPreCheck: builder.mutation<
      PreCheckTransactionResponse | undefined,
      GetPreCheckByCompanyProfileIdRequest
    >({
      query: ({ companyProfileId }) => ({
        apiMethod: async (client): Promise<ProductsApiResponse<PreCheckTransactionResponse>> => {
          const preCheckResponse = await client.products_CreatePreCheck(companyProfileId);

          if (preCheckResponse.errors) {
            return {
              errors: preCheckResponse.errors,
              meta: preCheckResponse.meta,
            };
          }

          return {
            data: preCheckResponse.data,
            meta: preCheckResponse.meta,
          };
        },
      }),
      invalidatesTags: [TagType.ProductApi_PreCheckTransactionResponse],
    }),
    startCalculation: builder.mutation<CalculationTransactionResponse | undefined, CalculationCreateRequest>({
      query: (body) => ({
        apiMethod: async (client): Promise<ProductsApiResponse<CalculationTransactionResponse>> => {
          const calculationResponse = await client.products_CreateCalculationTransaction(body);

          if (calculationResponse.errors) {
            return {
              errors: calculationResponse.errors,
              meta: calculationResponse.meta,
            };
          }

          return {
            data: calculationResponse.data,
            meta: calculationResponse.meta,
          };
        },
      }),
      invalidatesTags: [
        TagType.ProductApi_CalculationResponse,
        TagType.CompanyApi_CompanyProfileDto,
        TagType.CompanyApi_CompanyProfileLastRatingResponse,
      ],
    }),
    products_acceptCalculation: builder.mutation<CalculationAcceptResponse | undefined, CalculationAcceptRequest>({
      query: (body) => ({
        apiMethod: async (client): Promise<ProductsApiResponse<CalculationAcceptResponse>> => {
          const calculationResponse = await client.products_AcceptCalculation(body);

          if (calculationResponse.errors) {
            return {
              errors: calculationResponse.errors,
              meta: calculationResponse.meta,
            };
          }

          return {
            data: calculationResponse.data,
            meta: calculationResponse.meta,
          };
        },
      }),
      invalidatesTags: [TagType.ProductApi_AcceptCalculationResponse],
    }),
    products_getCalculationResultOfferStatus: builder.query<
      CalculationAcceptResponse | undefined,
      GetCalculationResultOfferStatusRequest
    >({
      query: ({ id }) => ({
        apiMethod: async (client): Promise<ProductsApiResponse<CalculationAcceptResponse>> => {
          const getCalculationResultOfferStatusResponse = await client.products_GetCalculationResultOfferStatus(id);

          if (getCalculationResultOfferStatusResponse.errors) {
            return {
              errors: getCalculationResultOfferStatusResponse.errors,
              meta: getCalculationResultOfferStatusResponse.meta,
            };
          }

          return {
            data: getCalculationResultOfferStatusResponse.data,
            meta: getCalculationResultOfferStatusResponse.meta,
          };
        },
      }),
      providesTags: (result) =>
        result
          ? [{ type: TagType.ProductApi_CalculationResultOfferStatusResponse, id: result.id }]
          : [TagType.ProductApi_CalculationResultOfferStatusResponse],
    }),
    products_getCalculations: builder.query<CalculationStatusResponse | undefined, GetCalculationsRequest>({
      query: ({ id }) => ({
        apiMethod: async (client): Promise<ProductsApiResponse<CalculationStatusResponse>> => {
          const getCalculationResultResponse = await client.products_GetCalculationStatus(id);

          if (getCalculationResultResponse.errors) {
            return {
              errors: getCalculationResultResponse.errors,
              meta: getCalculationResultResponse.meta,
            };
          }

          return {
            data: getCalculationResultResponse.data,
            meta: getCalculationResultResponse.meta,
          };
        },
      }),
      providesTags: (result) =>
        result?.results?.length
          ? result.results.map((result) => ({ type: TagType.ProductApi_CalculationsResponse, id: result.id }))
          : [TagType.ProductApi_CalculationsResponse],
    }),
  }),
});

export const {
  useProducts_createPreCheckMutation,
  useStartCalculationMutation,
  useProducts_getCalculationsQuery,
  useProducts_acceptCalculationMutation,
  useProducts_getCalculationResultOfferStatusQuery,
} = productsController;
