import { V1InquiriesApiClient } from "@capetec/inquiries-api-client";

import { getSessionToken } from "../clerkApi";

export const inquiriesClient = new V1InquiriesApiClient(
  {
    getToken: async (): Promise<string> => {
      const token = await getSessionToken();
      if (!token) {
        return "";
      }
      return `Bearer ${token}`;
    },
  },
  window.REACT_APP_INQUIRY_API,
);
