import { ContactCommunicationInformationResponse } from "@capetec/companies-api-client";
import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import FaxIcon from "@mui/icons-material/Fax";
import HomeIcon from "@mui/icons-material/Home";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PublicIcon from "@mui/icons-material/Public";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import Badge from "@mui/material/Badge";
import { TFunction } from "i18next";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { CommunicationInformationTypeValue } from "@/enums/CommunicationInformation";

import { ContactCommunicationInformationsListItem } from "./ContactCommunicationInformationsListItem";

type BadgeIconProps = {
  icon: ReactElement;
  badgeIcon: ReactElement;
};

const BadgeIcon = ({ icon, badgeIcon }: BadgeIconProps): JSX.Element => (
  <Badge
    badgeContent={badgeIcon}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
  >
    {icon}
  </Badge>
);

const listItemsToTypes: Record<
  CommunicationInformationTypeValue,
  (
    communicationInformation: ContactCommunicationInformationResponse,
    t: TFunction<"ContactCard", undefined>,
  ) => ReactElement
> = {
  [CommunicationInformationTypeValue.EmailBusiness]: ({ id, communicationInformationValue }, t) => (
    <ContactCommunicationInformationsListItem
      key={id}
      icon={
        <BadgeIcon
          badgeIcon={<BusinessIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
          icon={<EmailIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
        />
      }
      label={t("emailBusiness")}
      link={communicationInformationValue ? `mailto:${communicationInformationValue}` : ""}
      value={communicationInformationValue ?? "-"}
    />
  ),
  [CommunicationInformationTypeValue.EmailPrivate]: ({ id, communicationInformationValue }, t) => (
    <ContactCommunicationInformationsListItem
      key={id}
      icon={
        <BadgeIcon
          badgeIcon={<HomeIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
          icon={<EmailIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
        />
      }
      label={t("emailPrivate")}
      link={communicationInformationValue ? `mailto:${communicationInformationValue}` : ""}
      value={communicationInformationValue ?? "-"}
    />
  ),
  [CommunicationInformationTypeValue.FaxBusiness]: ({ id, communicationInformationValue }, t) => (
    <ContactCommunicationInformationsListItem
      key={id}
      icon={
        <BadgeIcon
          badgeIcon={<BusinessIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
          icon={<FaxIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
        />
      }
      label={t("faxBusiness")}
      value={communicationInformationValue ?? "-"}
    />
  ),
  [CommunicationInformationTypeValue.FaxPrivate]: ({ id, communicationInformationValue }, t) => (
    <ContactCommunicationInformationsListItem
      key={id}
      icon={
        <BadgeIcon
          badgeIcon={<HomeIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
          icon={<FaxIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
        />
      }
      label={t("faxPrivate")}
      value={communicationInformationValue ?? "-"}
    />
  ),
  [CommunicationInformationTypeValue.MobilePhoneBusiness]: ({ id, communicationInformationValue }, t) => (
    <ContactCommunicationInformationsListItem
      key={id}
      icon={
        <BadgeIcon
          badgeIcon={<BusinessIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
          icon={<SmartphoneIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
        />
      }
      label={t("mobilePhoneBusiness")}
      link={communicationInformationValue ? `tel:${communicationInformationValue}` : ""}
      value={communicationInformationValue ?? "-"}
    />
  ),
  [CommunicationInformationTypeValue.MobilePhonePrivate]: ({ id, communicationInformationValue }, t) => (
    <ContactCommunicationInformationsListItem
      key={id}
      icon={
        <BadgeIcon
          badgeIcon={<HomeIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
          icon={<SmartphoneIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
        />
      }
      label={t("mobilePhonePrivate")}
      link={communicationInformationValue ? `tel:${communicationInformationValue}` : ""}
      value={communicationInformationValue ?? "-"}
    />
  ),
  [CommunicationInformationTypeValue.TelephoneBusiness]: ({ id, communicationInformationValue }, t) => (
    <ContactCommunicationInformationsListItem
      key={id}
      icon={
        <BadgeIcon
          badgeIcon={<BusinessIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
          icon={<LocalPhoneIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
        />
      }
      label={t("telephoneBusiness")}
      link={communicationInformationValue ? `tel:${communicationInformationValue}` : ""}
      value={communicationInformationValue ?? "-"}
    />
  ),
  [CommunicationInformationTypeValue.TelephonePrivate]: ({ id, communicationInformationValue }, t) => (
    <ContactCommunicationInformationsListItem
      key={id}
      icon={
        <BadgeIcon
          badgeIcon={<HomeIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
          icon={<LocalPhoneIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
        />
      }
      label={t("telephonePrivate")}
      link={communicationInformationValue ? `tel:${communicationInformationValue}` : ""}
      value={communicationInformationValue ?? "-"}
    />
  ),
  [CommunicationInformationTypeValue.Website]: ({ id, communicationInformationValue }, t) => (
    <ContactCommunicationInformationsListItem
      key={id}
      icon={<PublicIcon fontSize="small" sx={(theme) => ({ color: theme.palette.primary.main })} />}
      label={t("website")}
      link={communicationInformationValue ? communicationInformationValue : ""}
      value={communicationInformationValue ?? "-"}
    />
  ),
};

type ContactCommunicationInformationsListItemTypeProps = {
  type: CommunicationInformationTypeValue;
  communicationInformation: ContactCommunicationInformationResponse;
};

export const ContactCommunicationInformationsListItemType = ({
  type,
  communicationInformation,
}: ContactCommunicationInformationsListItemTypeProps): JSX.Element => {
  const { t } = useTranslation("ContactCard");

  return listItemsToTypes[type](communicationInformation, t);
};
