import { ILookupCommunicationInformationTypeResponse } from "@capetec/companies-api-client";

export enum CommunicationInformationTypeValue {
  EmailBusiness = "EmailBusiness",
  EmailPrivate = "EmailPrivate",
  FaxBusiness = "FaxBusiness",
  FaxPrivate = "FaxPrivate",
  MobilePhoneBusiness = "MobilePhoneBusiness",
  MobilePhonePrivate = "MobilePhonePrivate",
  TelephoneBusiness = "TelephoneBusiness",
  TelephonePrivate = "TelephonePrivate",
  Website = "Website",
}

export const getCommunicationInformationTypeValueByString = (
  value: string,
): CommunicationInformationTypeValue | undefined =>
  Object.entries(CommunicationInformationTypeValue).find(([key]) => key === value)?.[1];

export const isCommunicationInformationTypeValueNumberInput = (
  response: ILookupCommunicationInformationTypeResponse,
): boolean => (response ? response.value.toUpperCase().includes("PHONE") : false);
