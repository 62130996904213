import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export enum CompanyInitializationStates {
  Done = "Done",
  Started = "Started",
  Loading = "Loading",
  Success = "Success",
}

export interface CompanyState {
  closedReleaseCompanyProfileDialog: boolean;
  loadingContact: CompanyInitializationStates;
  loadingCreditRating: CompanyInitializationStates;
  loadingCalculators: CompanyInitializationStates;
}

const initialState: CompanyState = {
  closedReleaseCompanyProfileDialog: false,
  loadingContact: CompanyInitializationStates.Done,
  loadingCreditRating: CompanyInitializationStates.Done,
  loadingCalculators: CompanyInitializationStates.Done,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyLoadingContactState: (state, action: PayloadAction<CompanyInitializationStates>) => {
      state.loadingContact = action.payload;
    },
    setCompanyLoadingCreditRatingState: (state, action: PayloadAction<CompanyInitializationStates>) => {
      state.loadingCreditRating = action.payload;
    },
    setCompanyLoadingCalculatorsState: (state, action: PayloadAction<CompanyInitializationStates>) => {
      state.loadingCalculators = action.payload;
    },
    setClosedReleaseCompanyProfileDialog: (state, action: PayloadAction<boolean>) => {
      state.closedReleaseCompanyProfileDialog = action.payload;
    },
  },
});

export const {
  setClosedReleaseCompanyProfileDialog,
  setCompanyLoadingContactState,
  setCompanyLoadingCreditRatingState,
  setCompanyLoadingCalculatorsState,
} = companySlice.actions;

export const CompanyReducer = companySlice.reducer;
