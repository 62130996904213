import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SupportedLanguage, getLanguage } from ".";

export const useLanguage = (): SupportedLanguage => {
  const { i18n } = useTranslation();

  const language = useMemo(() => getLanguage(i18n.language), [i18n.language]);
  return language;
};
