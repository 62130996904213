import {
  CompanyProfileProvidedDocumentResponse,
  FinalizeCompanyProfileDocumentUploadRequest,
  FinalizeInquiryDocumentUploadRequest,
  ICompanyProfileProvidedDocumentResponse,
  IInquiryProvidedDocumentResponse,
  IOfferProvidedDocumentResponse,
  InquiryProvidedDocumentResponse,
  OfferProvidedDocumentResponse,
  OffersDocumentCreationRequest,
  RequestedDocumentSetResponse,
  UploadDocumentResponse,
} from "@capetec/documents-api-client";

import { TagType } from "@/api/enums";

import { documentApi } from "../documentApi";
import { transformApiPaginationResponse } from "../helpers";
import { DocumentsApiResponse, FinalizeOfferProvideDocumentUploadParameters, GetCompanyProfileDocumentsParameters, GetOfferDocumentsParameters, GetS3UploadUrlParameters, PaginationResponse, PatchOfferProvidedDocumentParameter } from "../types";
import { GetInquiryDocumentsParameters } from "../types/documentsController/request/GetInquiryDocumentsParameters";

type RequestedDocumentSetRequest = {
  partnerProductId?: number;
  financingTypeId?: number;
  purposeId?: number;
  name?: string;
  vehicleTypeId?: number;
  machineTypeId?: number;
};

export const documentsController = documentApi.injectEndpoints({
  endpoints: (builder) => ({
    documents_CreateOfferDocuments: builder.query<
      OfferProvidedDocumentResponse[] | undefined,
      OffersDocumentCreationRequest
    >({
      query: (request) => ({
        apiMethod: async (client): Promise<DocumentsApiResponse<OfferProvidedDocumentResponse[]>> => {
          const response = await client.documents_CreateOfferDocuments(request);

          if (response.errors) {
            return {
              errors: response.errors,
              meta: response.meta,
            };
          }

          return {
            data: response.data,
            meta: response.meta,
          };
        },
      }),
      providesTags: (result) =>
        result?.length
          ? result.map((x) => ({ type: TagType.DocumentApi_CreateOfferReponse, id: x.id }))
          : [TagType.DocumentApi_CreateOfferReponse],
    }),

    documents_DeleteCompanyProfileProvidedDocument: builder.mutation<boolean | undefined, number>({
      query: (companyProfileProvidedDocumentId) => ({
        apiMethod: async (client): Promise<DocumentsApiResponse<boolean>> => {
          const response = await client.documents_DeleteCompanyProfileProvidedDocument(
            companyProfileProvidedDocumentId,
          );

          if (response.errors) {
            return {
              errors: response.errors,
              meta: response.meta,
            };
          }

          return {
            data: response.data,
            meta: response.meta,
          };
        },
      }),
      invalidatesTags: [TagType.DocumentApi_CompanyProfileDocumentsResponse],
    }),

    documents_DeleteInquiryProvidedDocument: builder.mutation<boolean | undefined, number>({
      query: (inquiryProvidedDocumentId) => ({
        apiMethod: async (client): Promise<DocumentsApiResponse<boolean>> => {
          const response = await client.documents_DeleteInquiryProvidedDocument(inquiryProvidedDocumentId);

          if (response.errors) {
            return {
              errors: response.errors,
              meta: response.meta,
            };
          }

          return {
            data: response.data,
            meta: response.meta,
          };
        },
      }),
      invalidatesTags: [TagType.DocumentApi_InquiryDocumentsResponse],
    }),

    documents_DeleteOfferProvidedDocument: builder.mutation<boolean | undefined, number>({
      query: (offerProvidedDocumentId) => ({
        apiMethod: async (client): Promise<DocumentsApiResponse<boolean>> => {
          const response = await client.documents_DeleteOfferProvidedDocument(offerProvidedDocumentId);

          if (response.errors) {
            return {
              errors: response.errors,
              meta: response.meta,
            };
          }

          return {
            data: response.data,
            meta: response.meta,
          };
        },
      }),
      invalidatesTags: [TagType.DocumentApi_OfferDocumentsResponse],
    }),

    documents_FinalizeCompanyProfileProvideDocumentUpload: builder.mutation<
      CompanyProfileProvidedDocumentResponse | undefined,
      FinalizeCompanyProfileDocumentUploadRequest
    >({
      query: (request) => ({
        apiMethod: async (client): Promise<DocumentsApiResponse<CompanyProfileProvidedDocumentResponse>> => {
          const response = await client.documents_FinalizeCompanyProfileProvideDocumentUpload(request);

          if (response.errors) {
            return {
              errors: response.errors,
              meta: response.meta,
            };
          }

          return {
            data: response.data,
            meta: response.meta,
          };
        },
      }),
      invalidatesTags: () => [TagType.DocumentApi_CompanyProfileDocumentsResponse],
    }),

    documents_FinalizeInquiryProvideDocumentUpload: builder.mutation<
      InquiryProvidedDocumentResponse | undefined,
      FinalizeInquiryDocumentUploadRequest
    >({
      query: (request) => ({
        apiMethod: async (client): Promise<DocumentsApiResponse<InquiryProvidedDocumentResponse>> => {
          const response = await client.documents_FinalizeInquiryProvideDocumentUpload(request);

          if (response.errors) {
            return {
              errors: response.errors,
              meta: response.meta,
            };
          }

          return {
            data: response.data,
            meta: response.meta,
          };
        },
      }),
      invalidatesTags: () => [TagType.DocumentApi_InquiryDocumentsResponse],
    }),

    documents_FinalizeOfferProvideDocumentUpload: builder.mutation<
      OfferProvidedDocumentResponse | undefined,
      FinalizeOfferProvideDocumentUploadParameters
    >({
      query: ({ request, signal }) => ({
        apiMethod: async (client): Promise<DocumentsApiResponse<OfferProvidedDocumentResponse>> => {
          const response = await client.documents_FinalizeOfferProvideDocumentUpload(request, signal);

          if (response.errors) {
            return {
              errors: response.errors,
              meta: response.meta,
            };
          }

          return {
            data: response.data,
            meta: response.meta,
          };
        },
      }),
      invalidatesTags: () => [TagType.DocumentApi_OfferDocumentsResponse],
    }),

    documents_GetCompanyProfileDocuments: builder.query<
      PaginationResponse<ICompanyProfileProvidedDocumentResponse> | undefined,
      GetCompanyProfileDocumentsParameters
    >({
      query: ({ companyProfileId, pageNumber, pageSize, sortBy, sortDirection }) => ({
        apiMethod: async (
          client,
        ): Promise<DocumentsApiResponse<PaginationResponse<ICompanyProfileProvidedDocumentResponse>>> => {
          const documentsResponse = await client.documents_GetCompanyProfileDocuments(companyProfileId, sortDirection, sortBy, pageNumber, pageSize);

          return transformApiPaginationResponse(documentsResponse);
        },
      }),
      providesTags: (result) =>
        result?.data?.length
          ? result.data.map((x) => ({ type: TagType.DocumentApi_CompanyProfileDocumentsResponse, id: x.id }))
          : [TagType.DocumentApi_CompanyProfileDocumentsResponse],
    }),

    documents_GetInquiryDocuments: builder.query<
      PaginationResponse<IInquiryProvidedDocumentResponse> | undefined,
      GetInquiryDocumentsParameters
    >({
      query: ({ inquiryId, pageNumber, pageSize, sortBy, sortDirection }) => ({
        apiMethod: async (
          client,
        ): Promise<DocumentsApiResponse<PaginationResponse<IInquiryProvidedDocumentResponse>>> => {
          const documentsResponse = await client.documents_GetInquiryDocuments(inquiryId, sortDirection, sortBy, pageNumber, pageSize);

          return transformApiPaginationResponse(documentsResponse);
        },
      }),
      providesTags: (result) =>
        result?.data?.length
          ? result.data.map((x) => ({ type: TagType.DocumentApi_InquiryDocumentsResponse, id: x.id }))
          : [TagType.DocumentApi_InquiryDocumentsResponse],
    }),

    documents_GetOfferDocuments: builder.query<PaginationResponse<IOfferProvidedDocumentResponse> | undefined, GetOfferDocumentsParameters>({
      query: ({ offerId, pageNumber, pageSize, sortBy, sortDirection }) => ({
        apiMethod: async (
          client,
        ): Promise<DocumentsApiResponse<PaginationResponse<IOfferProvidedDocumentResponse>>> => {
          const documentsResponse = await client.documents_GetOfferDocuments(offerId, sortDirection, sortBy, pageNumber, pageSize);

          return transformApiPaginationResponse(documentsResponse);
        },
      }),
      providesTags: (result) =>
        result?.data?.length
          ? result.data.map((x) => ({ type: TagType.DocumentApi_OfferDocumentsResponse, id: x.id }))
          : [TagType.DocumentApi_OfferDocumentsResponse],
    }),

    documents_GetRequestedDocuments: builder.query<
      RequestedDocumentSetResponse | undefined,
      RequestedDocumentSetRequest
    >({
      query: (request) => ({
        apiMethod: async (client): Promise<DocumentsApiResponse<RequestedDocumentSetResponse>> => {
          const uploadUrlResponse = await client.documents_GetRequestedDocuments(
            request.partnerProductId,
            request.financingTypeId,
            request.purposeId,
            request.name,
            request.vehicleTypeId,
            request.machineTypeId,
          );

          if (uploadUrlResponse.errors) {
            return {
              errors: uploadUrlResponse.errors,
              meta: uploadUrlResponse.meta,
            };
          }

          return {
            data: uploadUrlResponse.data,
            meta: uploadUrlResponse.meta,
          };
        },
      }),
      providesTags: (result) =>
        result?.id
          ? [{ type: TagType.DocumentApi_GetRequestedDocuments, id: result.id }]
          : [TagType.DocumentApi_GetRequestedDocuments],
    }),

    documents_GetS3UploadUrl: builder.mutation<UploadDocumentResponse | undefined, GetS3UploadUrlParameters>({
      query: ({ request, signal }) => ({
        apiMethod: async (client): Promise<DocumentsApiResponse<UploadDocumentResponse>> => {
          const uploadUrlResponse = await client.documents_GetS3UploadUrl(request, signal);

          if (uploadUrlResponse.errors) {
            return {
              errors: uploadUrlResponse.errors,
              meta: uploadUrlResponse.meta,
            };
          }

          return {
            data: uploadUrlResponse.data,
            meta: uploadUrlResponse.meta,
          };
        },
      }),
    }),

    documents_GetS3PrivateAccessUrl: builder.query<string | undefined, number>({
      query: (documentId) => ({
        apiMethod: async (client): Promise<DocumentsApiResponse<string>> => {
          const urlResponse = await client.documents_GetS3PrivateAccessUrl(documentId);

          if (urlResponse.errors) {
            return {
              errors: urlResponse.errors,
              meta: urlResponse.meta,
            };
          }

          return {
            data: urlResponse.data,
            meta: urlResponse.meta,
          };
        },
      }),
    }),

    documents_RejectDocumentUpload: builder.mutation<boolean | undefined, number>({
      query: (documentId) => ({
        apiMethod: async (client): Promise<DocumentsApiResponse<boolean>> => {
          const response = await client.documents_RejectDocumentUpload(documentId);

          if (response.errors) {
            return {
              errors: response.errors,
              meta: response.meta,
            };
          }

          return {
            data: response.data,
            meta: response.meta,
          };
        },
      }),
    }),

    documents_PatchOfferProvidedDocument: builder.mutation<OfferProvidedDocumentResponse | undefined, PatchOfferProvidedDocumentParameter>({
      query: ({ documentId, body, signal }) => ({
        apiMethod: async (client): Promise<DocumentsApiResponse<OfferProvidedDocumentResponse>> => {
          const response = await client.documents_PatchOfferProvidedDocument(documentId, body, signal);

          if (response.errors) {
            return {
              errors: response.errors,
              meta: response.meta,
            };
          }

          return {
            data: response.data,
            meta: response.meta,
          };
        },
      }),
    }),
  }),
});

export const {
  useDocuments_CreateOfferDocumentsQuery,
  useDocuments_GetCompanyProfileDocumentsQuery,
  useDocuments_GetInquiryDocumentsQuery,
  useDocuments_GetOfferDocumentsQuery,
  useDocuments_GetRequestedDocumentsQuery,
  useDocuments_GetS3PrivateAccessUrlQuery,

  useDocuments_DeleteCompanyProfileProvidedDocumentMutation,
  useDocuments_DeleteInquiryProvidedDocumentMutation,
  useDocuments_DeleteOfferProvidedDocumentMutation,
  useDocuments_RejectDocumentUploadMutation,
  useDocuments_FinalizeCompanyProfileProvideDocumentUploadMutation,
  useDocuments_FinalizeInquiryProvideDocumentUploadMutation,
  useDocuments_FinalizeOfferProvideDocumentUploadMutation,
  useDocuments_GetS3UploadUrlMutation,
  useDocuments_PatchOfferProvidedDocumentMutation,

  useLazyDocuments_CreateOfferDocumentsQuery,
  useLazyDocuments_GetS3PrivateAccessUrlQuery,
  useLazyDocuments_GetCompanyProfileDocumentsQuery,
} = documentsController;
