import { useTheme, Theme } from "@mui/material";
import { BarChart as MuiBarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

const dataset = [
  {
    london: 59,
    month: "Jan",
  },
  {
    london: 50,
    month: "Feb",
  },
  {
    london: 47,
    month: "Mar",
  },
  {
    london: 54,
    month: "Apr",
  },
  {
    london: 57,
    month: "May",
  },
  {
    london: 60,
    month: "June",
  },
  {
    london: 59,
    month: "July",
  },
  {
    london: 65,
    month: "Aug",
  },
  {
    london: 51,
    month: "Sept",
  },
  {
    london: 60,
    month: "Oct",
  },
  {
    london: 67,
    month: "Nov",
  },
  {
    london: 61,
    month: "Dec",
  },
];

const chartSetting = {
  yAxis: [
    {
      label: "",
    },
  ],
  series: [{ dataKey: "london" }],
  height: 350,
  sx: {
    [`& .${axisClasses.directionY}`]: {
      display: "none",
    },
    [`& .${axisClasses.directionX} .${axisClasses.tick}`]: {
      display: "none",
    },
    [`& .${axisClasses.directionX} .${axisClasses.line}`]: {
      display: "none",
    },
    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
      fill: "#a6a4a4",
    },
  },
};

export const BarChart = (): JSX.Element => {
  const theme: Theme = useTheme();

  return (
    <MuiBarChart
      dataset={dataset}
      slotProps={{
        bar: {
          rx: 6,
          ry: 6,
        },
      }}
      xAxis={[
        {
          scaleType: "band",
          // @ts-expect-error"categoryGapRatio" does not exist in type 'XAxisProps'
          categoryGapRatio: 0.5,
          dataKey: "month",
          tickPlacement: "extremities",
          tickLabelPlacement: "middle",
          colorMap: {
            type: "ordinal",
            colors: [theme.palette.primary.dark, theme.palette.primary.main],
          },
        },
      ]}
      {...chartSetting}
    />
  );
};
