import { createApi } from "@reduxjs/toolkit/query/react";
import { TagType } from "../enums";
import { inquiryApiBaseQuery } from "./inquiryApiBaseQuery";

export const inquiryApi = createApi({
  reducerPath: "inquiryApi",
  baseQuery: inquiryApiBaseQuery({
    // Fill in your own server starting URL here
    baseUrl: window.REACT_APP_INQUIRY_API,
  }),
  endpoints: () => ({}),
  tagTypes: Object.values(TagType),
});
