import { InquiriesApiResponse, PaginationResponse } from "../types";

export const transformInquiriesApiResponseToPaginationResponse = <T>(
  response: InquiriesApiResponse<T[]>,
): PaginationResponse<T> => {
  return {
    data: response.data || [],
    pagination: response.meta?.pagination,
  };
};

export const transformInquiriesApiResponseToDto = <T>(response: InquiriesApiResponse<T>): T | undefined =>
  response.data;

export function transformApiPaginationResponse<TData>(
  response: InquiriesApiResponse<TData[]>,
): InquiriesApiResponse<PaginationResponse<TData>> {
  if (response.errors) {
    return {
      errors: response.errors,
      meta: response.meta,
    };
  }

  const paginationData: PaginationResponse<TData> = {
    data: response.data ?? [],
    pagination: response.meta?.pagination,
  };

  return {
    data: paginationData,
    meta: response.meta,
  };
}
