import {
  BusinessConsentCreateRequest,
  BusinessConsentResponse,
  CompanyProfileCreateRequest,
  CompanyProfilePatchRequest,
  CompanyProfileResponse,
  CompanyProfileSearchResultResponse,
  CompanySearchResultResponse,
  ContactCreateRequest,
  ICompanyProfileAddressResponse,
  ICompanyProfileLastRatingReportResponse,
  ICompanyProfileResponse,
  IContactResponse,
  InsertMyRecentlyViewedCompanyProfileRequest,
  MyNotificationsReadRequest,
  MyNotificationsResponse,
  MyRecentlyViewedCompanyProfilesResponse,
} from "@capetec/companies-api-client";

import { TagType } from "@/api/enums";

import { companyApi } from "../companyApi";
import {
  provideTagsForICompanyProfileResponse,
  provideTagsForPaginationResponseICompanyProfileResponse,
  transformApiPaginationResponse,
} from "../helpers";
import {
  CompaniesApiResponse,
  GetCompanyProfileAddressesByCompanyProfileIdParameters,
  GetContactsByCompanyProfileIdParameters,
  GetMainContactByCompanyProfileIdParameters,
  GetMyCompanyProfilesParameters,
  GetMyRecentlyViewedCompanyProfilesParameters,
  PaginationResponse,
  SearchCompaniesParameters,
  SearchCompanyProfilesParameters,
} from "../types";
import { GetCompanyProfileByIdRequest } from "../types/companiesController/request/GetCompanyProfileByIdRequest";

interface ContactCreateRequestWithProfileId {
  companyProfileId: number;
  body: ContactCreateRequest;
}
interface BusinessConsentCreateRequestWithProfileId {
  companyProfileId: number;
  body: BusinessConsentCreateRequest;
}

export const companiesController = companyApi.injectEndpoints({
  endpoints: (builder) => ({
    companies_searchCompanies: builder.query<
      PaginationResponse<CompanySearchResultResponse> | undefined,
      SearchCompaniesParameters
    >({
      query: ({ name }) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<CompanySearchResultResponse>>> => {
          const searchCompaniesResponse = await client.companies_SearchCompanies(name);

          return transformApiPaginationResponse(searchCompaniesResponse);
        },
      }),
      providesTags: (result) =>
        result?.data?.length
          ? result.data.map((company) => ({ type: TagType.CompanyApi_CompanySearchResultDto, id: company.name }))
          : [TagType.CompanyApi_CompanySearchResultDto],
    }),
    companies_searchCompanyProfiles: builder.query<
      PaginationResponse<CompanyProfileSearchResultResponse> | undefined,
      SearchCompanyProfilesParameters
    >({
      query: ({ name, overrideMaxPageSize, pageNumber, pageSize }) => ({
        apiMethod: async (
          client,
        ): Promise<CompaniesApiResponse<PaginationResponse<CompanyProfileSearchResultResponse>>> => {
          const searchCompaniesResponse = await client.companies_SearchCompanyProfiles(
            name,
            overrideMaxPageSize,
            pageNumber,
            pageSize,
          );

          return transformApiPaginationResponse(searchCompaniesResponse);
        },
      }),
      providesTags: (result) =>
        result?.data?.length
          ? result.data.map((company) => ({ type: TagType.CompanyApi_CompanyProfileSearchResultDto, id: company.name }))
          : [TagType.CompanyApi_CompanyProfileSearchResultDto],
    }),
    companies_getRecentlyViewedCompanyProfiles: builder.query<
      PaginationResponse<MyRecentlyViewedCompanyProfilesResponse> | undefined,
      GetMyRecentlyViewedCompanyProfilesParameters | void
    >({
      query: (props) => ({
        apiMethod: async (
          client,
        ): Promise<CompaniesApiResponse<PaginationResponse<MyRecentlyViewedCompanyProfilesResponse>>> => {
          const { overrideMaxPageSize, pageNumber, pageSize } = props || {};
          const searchCompaniesResponse = await client.companies_GetMyRecentlyViewedCompanyProfiles(
            overrideMaxPageSize,
            pageNumber,
            pageSize,
          );

          return transformApiPaginationResponse(searchCompaniesResponse);
        },
      }),
      providesTags: [TagType.CompanyApi_MyRecentlyViewedCompanyProfilesResponse],
    }),
    companies_insertMyRecentlyViewedCompanyProfile: builder.mutation<
      MyRecentlyViewedCompanyProfilesResponse | undefined,
      InsertMyRecentlyViewedCompanyProfileRequest
    >({
      query: (body) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<MyRecentlyViewedCompanyProfilesResponse>> => {
          const searchCompaniesResponse = await client.companies_InsertMyRecentlyViewedCompanyProfile(body);

          if (searchCompaniesResponse.errors) {
            return {
              errors: searchCompaniesResponse.errors,
              meta: searchCompaniesResponse.meta,
            };
          }

          return {
            data: searchCompaniesResponse.data,
            meta: searchCompaniesResponse.meta,
          };
        },
      }),
      invalidatesTags: [TagType.CompanyApi_MyRecentlyViewedCompanyProfilesResponse],
    }),
    companies_createCompanyProfile: builder.mutation<CompanyProfileResponse | undefined, CompanyProfileCreateRequest>({
      query: (body) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<CompanyProfileResponse>> => {
          const companyProfileResponse = await client.companies_CreateCompanyProfile(body);

          if (companyProfileResponse.errors) {
            return {
              errors: companyProfileResponse.errors,
              meta: companyProfileResponse.meta,
            };
          }

          return {
            data: companyProfileResponse.data,
            meta: companyProfileResponse.meta,
          };
        },
      }),
      invalidatesTags: [
        TagType.CompanyApi_CompanyProfileDto,
        TagType.CompanyApi_CompanySearchResultDto,
        TagType.CompanyApi_CompanyProfileSearchResultDto,
      ],
    }),
    companies_getCompanyProfileById: builder.query<ICompanyProfileResponse | undefined, GetCompanyProfileByIdRequest>({
      query: ({ companyProfileId, params }) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<ICompanyProfileResponse>> => {
          const getCompanyProfileResponse = await client.companies_GetCompanyProfileById(
            companyProfileId,
            params?.include,
          );

          if (getCompanyProfileResponse.errors) {
            return {
              errors: getCompanyProfileResponse.errors,
              meta: getCompanyProfileResponse.meta,
            };
          }

          return {
            data: getCompanyProfileResponse.data,
            meta: getCompanyProfileResponse.meta,
          };
        },
      }),
      providesTags: provideTagsForICompanyProfileResponse,
    }),
    companies_getMyCompanyProfiles: builder.query<
      PaginationResponse<ICompanyProfileResponse> | undefined,
      GetMyCompanyProfilesParameters
    >({
      query: ({ include, name, sortBy, sortDirection, overrideMaxPageSize, pageNumber, pageSize }) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<ICompanyProfileResponse>>> => {
          const myCompanyProfilesResponse = await client.companies_GetMyCompanyProfiles(
            name,
            include,
            sortDirection,
            sortBy,
            overrideMaxPageSize,
            pageNumber,
            pageSize,
          );

          return transformApiPaginationResponse(myCompanyProfilesResponse);
        },
      }),
      providesTags: provideTagsForPaginationResponseICompanyProfileResponse,
    }),
    companies_getMyNotifications: builder.query<PaginationResponse<MyNotificationsResponse> | undefined, undefined>({
      query: () => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<MyNotificationsResponse>>> => {
          const myNotificationsResponse = await client.companies_GetMyNotifications();

          return transformApiPaginationResponse(myNotificationsResponse);
        },
      }),
      providesTags: (result) =>
        result?.data?.length
          ? result.data.map((notification) => ({
              type: TagType.CompanyApi_MyNotificationResponse,
              id: notification.id,
            }))
          : [TagType.CompanyApi_MyNotificationResponse],
    }),
    companies_completeNotifications: builder.mutation<MyNotificationsResponse | undefined, MyNotificationsReadRequest>({
      query: (body) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<MyNotificationsResponse>> => {
          const notificationsResponse = await client.companies_InsertMyMyNotifications(body);

          if (notificationsResponse.errors) {
            return {
              errors: notificationsResponse.errors,
              meta: notificationsResponse.meta,
            };
          }

          return {
            data: notificationsResponse.data,
            meta: notificationsResponse.meta,
          };
        },
      }),
      invalidatesTags: [TagType.CompanyApi_MyNotificationResponse],
    }),
    companies_getContactsByCompanyProfileId: builder.query<
      PaginationResponse<IContactResponse> | undefined,
      GetContactsByCompanyProfileIdParameters
    >({
      query: ({ companyProfileId, overrideMaxPageSize, pageNumber, pageSize }) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<PaginationResponse<IContactResponse>>> => {
          const companyProfileContactResponse = await client.companies_GetContactsByCompanyProfileId(
            companyProfileId,
            overrideMaxPageSize,
            pageNumber,
            pageSize,
          );

          return transformApiPaginationResponse(companyProfileContactResponse);
        },
      }),
      providesTags: (result) =>
        result?.data?.length
          ? result.data.map((contact) => ({ type: TagType.CompanyApi_ContactResponse, id: contact.id }))
          : [TagType.CompanyApi_ContactResponse],
    }),
    companies_getMainContactByCompanyProfileId: builder.query<
      IContactResponse | undefined,
      GetMainContactByCompanyProfileIdParameters
    >({
      query: ({ companyProfileId }) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<IContactResponse>> => {
          const getMainContactResponse = await client.companies_GetMainContactByCompanyProfileId(companyProfileId);

          if (getMainContactResponse.errors) {
            return {
              errors: getMainContactResponse.errors,
              meta: getMainContactResponse.meta,
            };
          }

          return {
            data: getMainContactResponse.data,
            meta: getMainContactResponse.meta,
          };
        },
      }),
      providesTags: (result) =>
        result ? [{ type: TagType.CompanyApi_ContactResponse, id: result.id }] : [TagType.CompanyApi_ContactResponse],
    }),
    companies_insertContact: builder.mutation<IContactResponse | undefined, ContactCreateRequestWithProfileId>({
      query: ({ companyProfileId, body }) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<IContactResponse>> => {
          const contactResponse = await client.companies_CreateMainContactForCompanyProfileId(companyProfileId, body);

          if (contactResponse.errors) {
            return {
              errors: contactResponse.errors,
              meta: contactResponse.meta,
            };
          }

          return {
            data: contactResponse.data,
            meta: contactResponse.meta,
          };
        },
      }),
      invalidatesTags: [TagType.CompanyApi_ContactResponse, TagType.CompanyApi_CompanyProfileDto],
    }),
    companies_insertBusinessConsent: builder.mutation<
      BusinessConsentResponse | undefined,
      BusinessConsentCreateRequestWithProfileId
    >({
      query: ({ companyProfileId, body }) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<BusinessConsentResponse>> => {
          const businessConcentResponse = await client.companies_CreateCompanyProfileBusinessConsent(
            companyProfileId,
            body,
          );

          if (businessConcentResponse.errors) {
            return {
              errors: businessConcentResponse.errors,
              meta: businessConcentResponse.meta,
            };
          }

          return {
            data: businessConcentResponse.data,
            meta: businessConcentResponse.meta,
          };
        },
      }),
      invalidatesTags: [
        TagType.CompanyApi_BusinessConsentResponse,
        TagType.CompanyApi_CompanyProfileDto,
        TagType.CompanyApi_ContactResponse,
      ],
    }),
    companies_getCompanyProfileAddressesByCompanyProfileId: builder.query<
      PaginationResponse<ICompanyProfileAddressResponse> | undefined,
      GetCompanyProfileAddressesByCompanyProfileIdParameters
    >({
      query: ({ companyProfileId, overrideMaxPageSize, pageNumber, pageSize }) => ({
        apiMethod: async (
          client,
        ): Promise<CompaniesApiResponse<PaginationResponse<ICompanyProfileAddressResponse>>> => {
          const companyProfileAddressesResponse = await client.companies_GetCompanyProfileAddressesByCompanyProfileId(
            companyProfileId,
            overrideMaxPageSize,
            pageNumber,
            pageSize,
          );

          return transformApiPaginationResponse(companyProfileAddressesResponse);
        },
      }),
      providesTags: (result) =>
        result?.data?.length
          ? result.data.map((contact) => ({ type: TagType.CompanyApi_AddressResponse, id: contact.id }))
          : [TagType.CompanyApi_AddressResponse],
    }),
    companies_patchCompanyProfile: builder.mutation<
      ICompanyProfileResponse | undefined,
      { companyProfileId: number; body: CompanyProfilePatchRequest }
    >({
      query: (request) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<ICompanyProfileResponse>> => {
          const patchCompanyProfileResponse = await client.companies_PatchCompanyProfile(
            request.companyProfileId,
            request.body,
          );

          if (patchCompanyProfileResponse.errors) {
            return {
              errors: patchCompanyProfileResponse.errors,
              meta: patchCompanyProfileResponse.meta,
            };
          }

          return {
            data: patchCompanyProfileResponse.data,
            meta: patchCompanyProfileResponse.meta,
          };
        },
      }),
      invalidatesTags: provideTagsForICompanyProfileResponse,
    }),
    companies_getLastCompanyProfileRatingReport: builder.query<
      ICompanyProfileLastRatingReportResponse | undefined,
      number
    >({
      query: (companyProfileId) => ({
        apiMethod: async (client): Promise<CompaniesApiResponse<ICompanyProfileLastRatingReportResponse>> => {
          const companyProfileLastRatingResponse =
            await client.companies_GetLastCompanyProfileRatingReport(companyProfileId);

          if (companyProfileLastRatingResponse.errors) {
            return {
              errors: companyProfileLastRatingResponse.errors,
              meta: companyProfileLastRatingResponse.meta,
            };
          }

          return {
            data: companyProfileLastRatingResponse.data,
            meta: companyProfileLastRatingResponse.meta,
          };
        },
      }),
      providesTags: (result) =>
        result
          ? [{ type: TagType.CompanyApi_CompanyProfileLastRatingResponse, id: result.id }]
          : [TagType.CompanyApi_CompanyProfileLastRatingResponse],
    }),
  }),
});

export const {
  useCompanies_searchCompaniesQuery,
  useCompanies_searchCompanyProfilesQuery,
  useCompanies_getRecentlyViewedCompanyProfilesQuery,
  useCompanies_insertMyRecentlyViewedCompanyProfileMutation,
  useCompanies_getCompanyProfileByIdQuery,
  useCompanies_getMyCompanyProfilesQuery,
  useCompanies_patchCompanyProfileMutation,
  useCompanies_getContactsByCompanyProfileIdQuery,
  useCompanies_getCompanyProfileAddressesByCompanyProfileIdQuery,
  useCompanies_createCompanyProfileMutation,
  useCompanies_getMainContactByCompanyProfileIdQuery,
  useCompanies_getLastCompanyProfileRatingReportQuery,
  useCompanies_insertContactMutation,
  useCompanies_insertBusinessConsentMutation,
  useCompanies_getMyNotificationsQuery,
  useCompanies_completeNotificationsMutation,
} = companiesController;
