import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useCompanyIdFromParams } from "@/hooks";
import { companySubpagesRouteTypes, getRouteTypeHref } from "@/routes/constants";
import { RouteType, isRouteTypeWithTranslation } from "@/types";

import { SubMenuButton } from "../subMenuButton";

const inquiryMenuItemsRouteTypes: RouteType[] = [
  RouteType.CompanyInformation,
  RouteType.CompanyInquiries,
  RouteType.CompanyInquiry,
];

export const InquirySubMenu = (): JSX.Element => {
  const { companyId } = useCompanyIdFromParams();
  const location = useLocation();
  const [selected, setSelected] = useState<RouteType>(RouteType.CompanyInquiry);

  useEffect(() => {
    const path = location.pathname;
    const sidebarItem = companySubpagesRouteTypes.find((item) => getRouteTypeHref(companyId, item) === path);
    if (sidebarItem) {
      setSelected(sidebarItem);
    }
  }, [location.pathname, companyId]);

  return (
    <Box role="presentation" sx={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
      {inquiryMenuItemsRouteTypes.map((type) =>
        isRouteTypeWithTranslation(type) ? (
          <SubMenuButton key={type} type={type} companyId={companyId} selected={selected} />
        ) : null,
      )}
    </Box>
  );
};
