import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

export const WelcomeNotification = (): JSX.Element => {
  return (
    <DialogContent dividers>
      <Typography>Liebe Vermögensberaterinnen,</Typography>
      <Typography sx={{ mb: 3 }} gutterBottom>
        Liebe Vermögensberater,
      </Typography>
      <Typography sx={{ mb: 3 }} gutterBottom>
        wir freuen uns sehr, Ihnen unsere neue Nutzerportal zur Erstellung von Liquiditäts- und Finanzierungslösungen
        für KMU vorzustellen! Ihr geschätztes Feedback haben wir dabei in die Entwicklung einfließen lassen, um Ihre
        Arbeit noch effizienter und erfolgreicher zu gestalten.
      </Typography>
      <Typography sx={{ mb: 3 }} gutterBottom>
        Probieren Sie es aus - wir wünschen Ihnen viel Freude und Erfolg bei der Nutzung!
      </Typography>
      <Typography>Herzliche Grüße,</Typography>
      <Typography>Ihr Bernd Mill und Marcus Aßmuth,</Typography>
    </DialogContent>
  );
};
