import { MyNotificationsResponse } from "@capetec/companies-api-client";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import "./translations";

import { WelcomeNotification } from "./notifications/WelcomeNotification";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

type MyNotificationsDialogProps = {
  open: boolean;
  notification: MyNotificationsResponse;
  handleClose: (notification: MyNotificationsResponse) => void;
};

export const MyNotificationsDialog = ({ notification, open, handleClose }: MyNotificationsDialogProps): JSX.Element => {
  const { t } = useTranslation("MyNotificationsDialog");

  return (
    <StyledDialog aria-labelledby="customized-dialog-title" open={open}>
      {notification?.value === "welcome" && <WelcomeNotification />}
      <DialogActions>
        <Button autoFocus onClick={() => handleClose(notification)}>
          {t("close")}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
