import { RouteObject } from "react-router";

import { ErrorPage } from "@/components/errorPage";
import { handleDynamicImportError } from "@/helpers/errorHelpers";
import { LazyFunctionReturnType, RouteType } from "@/types/routes";

export const tasksRoutes: RouteObject[] = [
  {
    path: "tasks",
    handle: {
      type: RouteType.Tasks,
    },
    children: [
      {
        index: true,
        async lazy(): Promise<LazyFunctionReturnType> {
          try {
            const Tasks = (await import("../features/tasks")).Tasks;
            return {
              element: <Tasks />,
            };
          } catch (error) {
            handleDynamicImportError(error);

            return {
              element: <ErrorPage />,
            };
          }
        },
      },
    ],
  },
];
