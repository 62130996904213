import * as React from "react";
import Button from "@mui/material/Button";
import { Menu as MuiMenu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ColorButton = styled(Button)(() => ({
  backgroundColor: "transparent",
  textTransform: "none",
  color: "#a6a4a4",
  padding: 0,
  marginTop: "5px",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export const Menu = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ColorButton
        id="demo-positioned-button"
        endIcon={<KeyboardArrowDownIcon sx={{ color: "white" }} />}
        onClick={handleClick}
      >
        Your menu item
      </ColorButton>
      <MuiMenu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </MuiMenu>
    </div>
  );
};
