import {
  CompanyProfileCreateRequest,
  CompanyProfileResponse,
  CompanySearchResultResponse,
  ICompanyProfileSearchResultResponse,
} from "@capetec/companies-api-client";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Alert, Box, Card, Collapse, IconButton, List, ListItem, ListItemText, TextField, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useCompanies_createCompanyProfileMutation } from "@/api/companyApi";
import { CompaniesApiResponse } from "@/api/companyApi/types";
import { LoadingList } from "@/components";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { CompanyResults } from "./companyResults";
import { setSearchQuery } from "./searchBarSlice";

const StyledTextField = styled(TextField)(({ theme }) => ({
  paddingLeft: "15px",
  backgroundColor: "rgba(255, 255, 255 ,1)",
  overflow: "hidden",
  border: "1px solid #d1cfcf",
  borderRadius: "10px",
  "&& .MuiInput-root::before": {
    border: "none",
  },
  "&:hover": {
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
    backgroundColor: "rgba(255, 255, 255 ,1)",
  },
}));

export const StartPageSearchBar = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("SearchBar");

  const [companySearchResultsShown, setCompanySearchResultsShown] = useState(false);
  const [createCompanyProfile, { isLoading: isCreateCompanyProfileLoading, isError: isCreateCompanyProfileError }] =
    useCompanies_createCompanyProfileMutation();

  const textFieldRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const outBoxRef = useRef<HTMLDivElement | null>(null);
  const btnToggleSearchResultsRef = useRef<HTMLButtonElement | null>(null);

  const searchQuery = useAppSelector((state) => state.searchBar.searchQuery);

  useEffect(() => {
    const currentOutBoxRef = outBoxRef.current;
    if (!currentOutBoxRef) return;

    const documentClick = (e: MouseEvent): void => {
      if (!companySearchResultsShown) return;
      const target = e.target as Node;
      if (currentOutBoxRef.contains(target) || btnToggleSearchResultsRef.current?.contains(target)) return;
      setCompanySearchResultsShown(false);
    };
    document.addEventListener("click", documentClick);

    return (): void => {
      document.removeEventListener("click", documentClick);
    };
  }, [anchorEl, companySearchResultsShown]);

  const executeCreateCompanyProfile = async (
    name: string,
    crefoId: string,
  ): Promise<CompaniesApiResponse<CompanyProfileResponse>> => {
    return await createCompanyProfile(new CompanyProfileCreateRequest({ name, crefoId }));
  };

  const handleInputTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const searchQuery = event.target.value;
    if (searchQuery.trim() === "") {
      resetAndCloseCompanySearchResults();
      return;
    }
    setAnchorEl(textFieldRef.current);
    dispatch(setSearchQuery(event.target.value));
    setCompanySearchResultsShown(true);
  };

  const resetAndCloseCompanySearchResults = (): void => {
    dispatch(setSearchQuery(""));
    setCompanySearchResultsShown(false);
  };

  const handleCompanyProfileClick = (companyProfile: ICompanyProfileSearchResultResponse): void => {
    navigate(`/companies/${companyProfile.id}`);
    resetAndCloseCompanySearchResults();
  };

  const handleCompanySuggestionClick = async (companySuggestion: CompanySearchResultResponse): Promise<void> => {
    const createdCompanyProfile = await executeCreateCompanyProfile(companySuggestion.name, companySuggestion.crefoId);
    if (!createdCompanyProfile.data) return;
    navigate(`/companies/${createdCompanyProfile.data.id}`);
    resetAndCloseCompanySearchResults();
  };

  const handleToggleSearchResults = (): void => {
    const newValue = !companySearchResultsShown;
    setCompanySearchResultsShown(newValue);
    setAnchorEl(newValue ? textFieldRef.current : null);
  };

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 3,
      }}
    >
      <StyledTextField
        ref={textFieldRef}
        placeholder={!isCreateCompanyProfileLoading ? t("placeholder") : t("companyIsBeingCreated")}
        sx={{ pr: 0 }}
        disabled={isCreateCompanyProfileLoading}
        InputProps={{
          disableUnderline: true,
          startAdornment: isCreateCompanyProfileLoading ? (
            <Box sx={{ mr: 2, display: "flex" }}>
              <CircularProgress size={30} />
            </Box>
          ) : undefined,
          endAdornment: (
            <IconButton
              ref={btnToggleSearchResultsRef}
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
                height: "100%",
                py: 2.3,
                width: "65px",
                borderRadius: 0,
              })}
              onClick={handleToggleSearchResults}
            >
              <Box sx={{ pointerEvents: "none", height: "20px", width: "20px", display: "flex", alignItems: "center" }}>
                {companySearchResultsShown ? (
                  <CloseIcon sx={{ color: "white", height: "100%", width: "100%" }} />
                ) : (
                  <SearchIcon sx={{ color: "white", height: "100%", width: "100%" }} />
                )}
              </Box>
            </IconButton>
          ),
        }}
        inputProps={{
          sx: {
            "&::placeholder": {
              color: "#bfc1c7",
              opacity: 1,
              fontWeight: 500,
            },
          },
        }}
        variant="standard"
        fullWidth
        value={searchQuery}
        onChange={handleInputTextChange}
        autoFocus
      />
      <Box
        ref={outBoxRef}
        sx={{
          position: "absolute",
          ...(anchorEl && {
            top: anchorEl.getBoundingClientRect().height,
            width: anchorEl.getBoundingClientRect().width,
          }),
        }}
      >
        <Collapse in={companySearchResultsShown}>
          <Card sx={{ mt: 2 }} variant="primaryCard">
            <List sx={{ p: 2, boxShadow: "none" }} component="nav">
              {isCreateCompanyProfileLoading && <LoadingList itemAmount={6} />}
              {isCreateCompanyProfileError && <Alert severity="error">{t("loadingError")}</Alert>}
              {!isCreateCompanyProfileLoading && !isCreateCompanyProfileError && (
                <>
                  {searchQuery === "" ? (
                    <ListItem>
                      <ListItemText primary={t("noSearchString")} />
                    </ListItem>
                  ) : (
                    <CompanyResults
                      searchQuery={searchQuery}
                      onCompanyProfileClick={handleCompanyProfileClick}
                      onCompanySuggestionClick={handleCompanySuggestionClick}
                    />
                  )}
                </>
              )}
            </List>
          </Card>
        </Collapse>
      </Box>
    </Box>
  );
};
