import { Box, Skeleton } from "@mui/material";

export type LoadingListProps = {
  itemAmount?: number;
  height?: string;
};

export const LoadingList = ({ itemAmount = 5, height }: LoadingListProps): JSX.Element => {
  return (
    <Box>
      {Array.from(Array(itemAmount).keys()).map((key) => (
        <Skeleton sx={{ my: 1 }} variant="rounded" key={key} height={height} />
      ))}
    </Box>
  );
};
