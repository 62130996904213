import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { ThemeName } from "./enums";

export interface ThemeState {
  themeName: ThemeName;
}

const initialState: ThemeState = {
  themeName: ThemeName.LightTheme,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setThemeName: (state, action: PayloadAction<ThemeName>) => {
      state.themeName = action.payload;
    },
  },
});

export const { setThemeName } = themeSlice.actions;

export default themeSlice.reducer;
