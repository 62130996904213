import type { Localization as MuiCoreLocalization } from "@mui/material/locale";
import { Localization as DataGridLocalization } from "@mui/x-data-grid/internals";
import type { PickersInputLocaleText } from "@mui/x-date-pickers/locales";
import { DateTime } from "luxon";
import { LocalizationResource } from '@clerk/types';

export enum SupportedLanguagesCode {
  // english languages
  en = "en",
  enUS = "en-US",

  // german languages
  de = "de",
  deDE = "de-DE",
}

export type SupportedLanguage = {
  code: SupportedLanguagesCode;
  shortName: string;
  name: string;
  /**
   * Localization for the x date pickers library.
   * https://mui.com/x/react-date-pickers/localization/#supported-locales
   */
  xDatePickersLocale?: PickersInputLocaleText<DateTime>;
  /**
   * Localization for the mui library.
   * https://mui.com/material-ui/guides/localization/
   */
  muiLocalization?: MuiCoreLocalization;
  /**
   * Localization for the x data grid library.
   * https://mui.com/x/react-data-grid/localization/
   */
  dataGridLocalization?: DataGridLocalization;
  clerkLocalization?: LocalizationResource;
};

export type AddResourceBundle = {
  languageCode: SupportedLanguagesCode;
  namespace: string;
  resources: Record<string, unknown>;
};
