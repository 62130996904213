import { Box, Grid } from "@mui/material";

import { RecentlyViewedCompanyProfilesSearchDialogList } from "./recentlyViewedCompanyProfilesSearchDialogList";

type EmptySearchQueryContentProps = {
  onItemOpenClick: () => void;
};

export const EmptySearchQueryContent = ({ onItemOpenClick }: EmptySearchQueryContentProps): JSX.Element => {
  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RecentlyViewedCompanyProfilesSearchDialogList onItemOpenClick={onItemOpenClick} />
        </Grid>
      </Grid>
    </Box>
  );
};
