import { ICompanyProfileSearchResultResponse } from "@capetec/companies-api-client";
import { Box, List } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CompanyResults } from "@/features/searchBar/companyResults";
import { useAppSelector } from "@/store/hooks";

type SearchQueryContentProps = {
  onItemOpenClick: () => void;
};

export const SearchQueryContent = ({ onItemOpenClick }: SearchQueryContentProps): JSX.Element => {
  const navigate = useNavigate();

  const searchQuery = useAppSelector((state) => state.searchBar.searchQuery);

  const handleCompanyProfileClick = (companyProfile: ICompanyProfileSearchResultResponse): void => {
    navigate(`/companies/${companyProfile.id}`);
    onItemOpenClick();
  };
  const handleCompanySuggestionClick = (): void => {
    // navigate(`/companies/${companyProfile.id}`);
  };

  return (
    <Box>
      <List sx={{ width: "100%" }} component="nav">
        <CompanyResults
          searchQuery={searchQuery}
          onCompanyProfileClick={handleCompanyProfileClick}
          onCompanySuggestionClick={handleCompanySuggestionClick}
        />
      </List>
    </Box>
  );
};
