import { SupportedLanguagesCode, addResourceBundles } from "../../../i18n";
import de from "./de.json";
import en from "./en.json";

export const namespace = "StartPage";

declare module "i18next" {
  interface CustomTypeResources {
    [namespace]: typeof de;
  }
}

addResourceBundles([
  { languageCode: SupportedLanguagesCode.de, namespace, resources: de },
  { languageCode: SupportedLanguagesCode.en, namespace, resources: en },
]);
