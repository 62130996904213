import { Theme, createTheme } from "@mui/material";
import { LinkProps } from "@mui/material/Link";

import { LinkBehavior } from "../behaviors";
import { scrollBarWidth } from "../constants";

const themeColors = {
  primary: {
    light: "#8ee1e3",
    main: "#3bcad1",
    dark: "#2b8a91",
  },
  secondary: {
    light: "#383838",
    main: "#2a2a2a",
    dark: "#191919",
  },
  background: {
    default: "#191919",
  },
  common: {
    text: "#a6a4a4",
  },
};

export const DarkTheme = (...args: object[]): Theme =>
  createTheme(
    {
      palette: {
        mode: "dark",
        primary: {
          main: themeColors.primary.main,
          dark: themeColors.primary.dark,
        },
        secondary: {
          light: themeColors.secondary.light,
          main: themeColors.secondary.main,
          dark: themeColors.secondary.dark,
        },
      },
      sidebar: {
        background: themeColors.secondary.dark,
        borderRadius: "0px 5px 5px 0px",
        textColor: "white",
        secondaryTextColor: "rgba(255, 255, 255, 0.8)",
        drawerWidth: 180,
        drawerWidthClosed: 90,
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            html: {
              /* width */
              "*::-webkit-scrollbar": {
                width: scrollBarWidth,
              },
              /* Track */
              "*::-webkit-scrollbar-track": {
                background: "transparent",
              },
              /* Handle */
              "*::-webkit-scrollbar-thumb": {
                height: "40px",
                border: `5px solid rgba(0, 0, 0, 0)`,
                backgroundClip: "padding-box",
                borderRadius: "12px",
                backgroundColor: "rgba(255, 255, 255, 0.4)",
              },
              /* Handle on hover */
              "*::-webkit-scrollbar-thumb:hover": {
                border: `2px solid rgba(0, 0, 0, 0)`,
                backgroundClip: "padding-box",
                borderRadius: "12px",
                backgroundColor: "rgba(255 255, 255, 0.4)",
              },
              "*::-webkit-scrollbar-corner": {
                background: "transparent",
              },
            },
          },
        },
        MuiLink: {
          defaultProps: {
            component: LinkBehavior,
          } as LinkProps,
        },
        MuiButtonBase: {
          defaultProps: {
            LinkComponent: LinkBehavior,
          },
        },
      },
    },
    args,
  );
