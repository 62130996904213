import { Box, useMediaQuery } from "@mui/material";
import { ReactNode } from "react";

export type NoLogoFullPageProps = {
  children: ReactNode;
};

export const NoLogoFullPage = ({ children }: NoLogoFullPageProps): JSX.Element => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        ...(prefersDarkMode
          ? { background: "linear-gradient(to bottom,#27272a,#18181b)" }
          : { background: "linear-gradient(to bottom,#f1f5f9,#e2e8f0)" }),
      }}
    >
      {children}
    </Box>
  );
};
