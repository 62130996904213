import { DocumentsApiResponse, PaginationResponse } from "../types";

export const transformDocumentsApiResponseToPaginationResponse = <T>(
  response: DocumentsApiResponse<T[]>,
): PaginationResponse<T> => {
  return {
    data: response.data || [],
    pagination: response.meta?.pagination,
  };
};

export const transformDocumentsApiResponseToDto = <T>(response: DocumentsApiResponse<T>): T | undefined =>
  response.data;

export function transformApiPaginationResponse<TData>(
  response: DocumentsApiResponse<TData[]>,
): DocumentsApiResponse<PaginationResponse<TData>> {
  if (response.errors) {
    return {
      errors: response.errors,
      meta: response.meta,
    };
  }

  const paginationData: PaginationResponse<TData> = {
    data: response.data ?? [],
    pagination: response.meta?.pagination,
  };

  return {
    data: paginationData,
    meta: response.meta,
  };
}
