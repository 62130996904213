import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import "./windowConfiguration.ts";

import "@/i18n/initI18n.ts";
import { LocalizationProvider } from "@/providers";
import { store } from "@/store/store.ts";
import { ThemeProviderWrapper } from "@/theme/ThemeProvider.tsx";

import { routes } from "./routes/routes";

Sentry.init({
  enabled: process.env.NODE_ENV !== "development",
  dsn: import.meta.env.REACT_APP_SENTRY_DSN,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 1.0,

  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProviderWrapper>
        <LocalizationProvider>
          <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
            <RouterProvider router={router} />
          </Sentry.ErrorBoundary>
        </LocalizationProvider>
      </ThemeProviderWrapper>
    </Provider>
  </React.StrictMode>,
);
