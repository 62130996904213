import { IInquiryPurposeRealEstateResponse, IInquiryResponse, InquiryPurposeMachineRequest, InquiryPurposeRealEstateRequest, InquiryPurposeValue, InquiryPurposeVehicleRequest, InquiryRealEstateProjectTypeValue, PatchInquiryPurposeOfUseRequest } from "@capetec/inquiries-api-client";
import { DebtRescheduling, Factoring, FollowUpFinancing, Goods, Liquidity, Machine, Modernization, NewConstruction, ProjectDevelopment, Purchase, PurposeOfUse, RealEstate, RealEstateProject, ShareAcquisition, Vehicle } from "../types";
import { DateTime } from "luxon";

// #region map front-end data to back-end data
export const getPatchInquiryPurposeOfUseRequestForSchemaData = (data: PurposeOfUse): PatchInquiryPurposeOfUseRequest | undefined => {
  switch (data.active) {
    case InquiryPurposeValue.RealEstate:
      return getPatchInquiryPurposeOfUseRequestRealEstate(data);
    case InquiryPurposeValue.Vehicle:
      return getPatchInquiryPurposeOfUseRequestVehicle(data);
    case InquiryPurposeValue.DebtRescheduling:
      return getPatchInquiryPurposeOfUseRequestDebtRescheduling(data);
    case InquiryPurposeValue.Liquidity:
      return getPatchInquiryPurposeOfUseRequestLiquidity(data);
    case InquiryPurposeValue.Factoring:
      return getPatchInquiryPurposeOfUseRequestFactoring(data);
    case InquiryPurposeValue.Goods:
      return getPatchInquiryPurposeOfUseRequestGoods(data);
    case InquiryPurposeValue.ShareAcquisition:
      return getPatchInquiryPurposeOfUseRequestShareAcquisition(data);
    case InquiryPurposeValue.Machine:
      return getPatchInquiryPurposeOfUseRequestMachine(data);
  }
}

const getPatchInquiryPurposeOfUseRequestRealEstate = (data: PurposeOfUse): PatchInquiryPurposeOfUseRequest | undefined => {
  const { realEstate } = data;
  const { amount, activeProjectType } = realEstate || {};
  if (!realEstate || amount == null || !activeProjectType) return;

  const inquiryPurposeRealEstate: InquiryPurposeRealEstateRequest = new InquiryPurposeRealEstateRequest();
  inquiryPurposeRealEstate.realEstateProjectTypeValue = activeProjectType.value;
  switch (inquiryPurposeRealEstate.realEstateProjectTypeValue) {
    case InquiryRealEstateProjectTypeValue.ProjectDevelopment:
      inquiryPurposeRealEstate.projectVolume = realEstate.projectType?.projectDevelopment?.projectVolume;
      inquiryPurposeRealEstate.projectDescription = realEstate.projectType?.projectDevelopment?.projectDescription;
      break;
    case InquiryRealEstateProjectTypeValue.FollowUpFinancing:
      inquiryPurposeRealEstate.followUpAmount = realEstate.projectType?.followUpFinancing?.followUpAmount;
      inquiryPurposeRealEstate.residentialUseShare = realEstate.projectType?.followUpFinancing?.residentialUseShare;
      break;
    case InquiryRealEstateProjectTypeValue.ReconstructionModernisation:
      inquiryPurposeRealEstate.reconstructionCost = realEstate.projectType?.modernization?.renovationCost;
      break;
    case InquiryRealEstateProjectTypeValue.NewBuilding:
      inquiryPurposeRealEstate.realEstateTypeValue = realEstate.projectType?.newConstruction?.financingObject;
      inquiryPurposeRealEstate.constructionYearMonth = realEstate.projectType?.newConstruction?.plannedStartDate;
      inquiryPurposeRealEstate.residentialUseShare = realEstate.projectType?.newConstruction?.residentialUseShare;
      break;
    case InquiryRealEstateProjectTypeValue.Purchase:
      inquiryPurposeRealEstate.realEstateTypeValue = realEstate.projectType?.purchase?.objectType;
      inquiryPurposeRealEstate.purchasePrice = realEstate.projectType?.purchase?.purchasePrice;
      inquiryPurposeRealEstate.residentialUseShare = realEstate.projectType?.purchase?.residentialUseShare;
      break;
  }

  return new PatchInquiryPurposeOfUseRequest({
    amount,
    purposeValue: InquiryPurposeValue.RealEstate,
    inquiryPurposeRealEstate,
  })
};

const getPatchInquiryPurposeOfUseRequestVehicle = (data: PurposeOfUse): PatchInquiryPurposeOfUseRequest | undefined => {
  const { vehicle } = data;
  const { purchasePriceNet, quantity, isNew, vehicleCategory, vehicleType } = vehicle || {};
  if (!vehicle || purchasePriceNet == null || quantity == null || isNew == null || vehicleType == null) return;

  return new PatchInquiryPurposeOfUseRequest({
    amount: purchasePriceNet * quantity,
    purposeValue: InquiryPurposeValue.Vehicle,
    inquiryPurposeVehicle: new InquiryPurposeVehicleRequest({
      purchasePrice: purchasePriceNet,
      quantity,
      newVehicle: isNew,
      vehicleType: vehicleType.value,
      vehicleSubType: vehicleCategory?.value,
    })
  });
};

const getPatchInquiryPurposeOfUseRequestDebtRescheduling = (data: PurposeOfUse): PatchInquiryPurposeOfUseRequest | undefined => {
  const { debtRescheduling } = data;
  const { financedAmount, purpose } = debtRescheduling || {};
  if (financedAmount == null || !purpose) return;

  return new PatchInquiryPurposeOfUseRequest({
    amount: financedAmount,
    purposeValue: InquiryPurposeValue.DebtRescheduling,
    description: purpose,
  });
};

const getPatchInquiryPurposeOfUseRequestLiquidity = (data: PurposeOfUse): PatchInquiryPurposeOfUseRequest | undefined => {
  const { liquidity } = data;
  const { financedAmount } = liquidity || {};
  if (financedAmount == null) return;

  return new PatchInquiryPurposeOfUseRequest({
    amount: financedAmount,
    purposeValue: InquiryPurposeValue.Liquidity,
  });
};

const getPatchInquiryPurposeOfUseRequestFactoring = (data: PurposeOfUse): PatchInquiryPurposeOfUseRequest | undefined => {
  const { factoring } = data;
  const { financedAmount } = factoring || {};
  if (financedAmount == null) return;

  return new PatchInquiryPurposeOfUseRequest({
    amount: financedAmount,
    purposeValue: InquiryPurposeValue.Factoring,
  });
};

const getPatchInquiryPurposeOfUseRequestGoods = (data: PurposeOfUse): PatchInquiryPurposeOfUseRequest | undefined => {
  const { goods } = data;
  const { financedAmount, purpose } = goods || {};
  if (financedAmount == null || !purpose) return;

  return new PatchInquiryPurposeOfUseRequest({
    amount: financedAmount,
    purposeValue: InquiryPurposeValue.Goods,
    description: purpose,
  });
};

const getPatchInquiryPurposeOfUseRequestShareAcquisition = (data: PurposeOfUse): PatchInquiryPurposeOfUseRequest | undefined => {
  const { shareAcquisition } = data;
  const { financedAmount, purpose } = shareAcquisition || {};
  if (financedAmount == null || !purpose) return;

  return new PatchInquiryPurposeOfUseRequest({
    amount: financedAmount,
    purposeValue: InquiryPurposeValue.ShareAcquisition,
    description: purpose,
  });
};

const getPatchInquiryPurposeOfUseRequestMachine = (data: PurposeOfUse): PatchInquiryPurposeOfUseRequest | undefined => {
  const { machine } = data;
  const { category, purchasePriceNet, isNew, isOrdered } = machine || {};
  if (!category || purchasePriceNet == null || isNew == null || isOrdered == null) return;

  return new PatchInquiryPurposeOfUseRequest({
    amount: purchasePriceNet,
    purposeValue: InquiryPurposeValue.Machine,
    inquiryPurposeMachine: new InquiryPurposeMachineRequest({
      machineType: category.value,
      purchasePrice: purchasePriceNet,
      newMachine: isNew,
      alreadyOrdered: isOrdered,
    }),
  });
};

//#endregion map front-end data to back-end data

//#region map back-end data to front-end data

export const mapInquiryToPurposeOfUse = (inquiry: IInquiryResponse | undefined): PurposeOfUse | undefined => {
  if (!inquiry) return;

  const { purposeValue } = inquiry;
  if (!purposeValue) return;
  const purposeOfUse: PurposeOfUse = {
    active: purposeValue,
    realEstate: mapInquiryPurposeRealEstateToRealEstate(inquiry),
    vehicle: mapInquiryPurposeVehicleToVehicle(inquiry),
    debtRescheduling: mapInquiryPurposeDebtReschedulingToDebtRescheduling(inquiry),
    factoring: mapInquiryPurposeFactoringToFactoring(inquiry),
    liquidity: mapInquiryPurposeLiquidityToLiquidity(inquiry),
    goods: mapInquiryPurposeGoodsToGoods(inquiry),
    shareAcquisition: mapInquiryPurposeShareAcquisitionToShareAcquisition(inquiry),
    machine: mapInquiryPurposeMachineToMachine(inquiry),
  }

  return purposeOfUse;
}

//#region real estate
const mapInquiryPurposeRealEstateToRealEstate = (inquiry: IInquiryResponse): RealEstate | undefined => {
  const { inquiryPurposeRealEstate, amount } = inquiry;
  if (!inquiryPurposeRealEstate) return;
  const { realEstateProjectTypeValue, realEstateProjectTypeLabel } = inquiryPurposeRealEstate;
  const realEstate: RealEstate = {
    amount,
    activeProjectType: realEstateProjectTypeValue ? {
      createdDateTime: DateTime.now(),
      id: -1,
      value: realEstateProjectTypeValue,
      name: realEstateProjectTypeLabel || "",
    } : undefined,
    projectType: mapInquiryPurposeRealEstateProjectToRealEstateProject(inquiryPurposeRealEstate),
  };
  return realEstate;
}

const mapInquiryPurposeRealEstateProjectToRealEstateProject = (inquiryPurposeRealEstate: IInquiryPurposeRealEstateResponse): RealEstateProject | undefined => {
  const { realEstateProjectTypeValue } = inquiryPurposeRealEstate;
  if (!realEstateProjectTypeValue) return;
  const realEstateProject: RealEstateProject = {
    projectDevelopment: mapInquiryPurposeRealEstateProjectDevelopmentToProjectDevelopment(inquiryPurposeRealEstate),
    followUpFinancing: mapInquiryPurposeRealEstateFollowUpFinancingToFollowUpFinancing(inquiryPurposeRealEstate),
    newConstruction: mapInquiryPurposeRealEstateNewConstructionToNewConstruction(inquiryPurposeRealEstate),
    purchase: mapInquiryPurposeRealEstatePurchaseToPurchase(inquiryPurposeRealEstate),
    modernization: mapInquiryPurposeRealEstateModernizationToModernization(inquiryPurposeRealEstate),
  };
  return realEstateProject;
}

const mapInquiryPurposeRealEstateProjectDevelopmentToProjectDevelopment = (inquiryPurposeRealEstate: IInquiryPurposeRealEstateResponse): ProjectDevelopment | undefined => {
  const { projectVolume, projectDescription } = inquiryPurposeRealEstate;
  if (projectVolume == null || !projectDescription) return;
  return {
    projectVolume,
    projectDescription,
  }
}

const mapInquiryPurposeRealEstateFollowUpFinancingToFollowUpFinancing = (inquiryPurposeRealEstate: IInquiryPurposeRealEstateResponse): FollowUpFinancing | undefined => {
  const { followUpAmount, residentialUseShare } = inquiryPurposeRealEstate;
  if (followUpAmount == null || residentialUseShare == null) return;
  return {
    followUpAmount,
    residentialUseShare,
  }
}

const mapInquiryPurposeRealEstateNewConstructionToNewConstruction = (inquiryPurposeRealEstate: IInquiryPurposeRealEstateResponse): NewConstruction | undefined => {
  const { realEstateTypeValue, constructionYearMonth, residentialUseShare } = inquiryPurposeRealEstate;
  if (!realEstateTypeValue || !constructionYearMonth || residentialUseShare == null) return;
  return {
    financingObject: realEstateTypeValue,
    plannedStartDate: constructionYearMonth,
    residentialUseShare,
  }
}

const mapInquiryPurposeRealEstatePurchaseToPurchase = (inquiryPurposeRealEstate: IInquiryPurposeRealEstateResponse): Purchase | undefined => {
  const { realEstateTypeValue, purchasePrice, residentialUseShare } = inquiryPurposeRealEstate;
  if (!realEstateTypeValue || purchasePrice == null || residentialUseShare == null) return;
  return {
    objectType: realEstateTypeValue,
    purchasePrice,
    residentialUseShare,
  }
}

const mapInquiryPurposeRealEstateModernizationToModernization = (inquiryPurposeRealEstate: IInquiryPurposeRealEstateResponse): Modernization | undefined => {
  const { reconstructionCost } = inquiryPurposeRealEstate;
  if (reconstructionCost == null) return;
  return {
    renovationCost: reconstructionCost,
  }
}
//#endregion real estate

//#region vehicle
const mapInquiryPurposeVehicleToVehicle = (inquiry: IInquiryResponse): Vehicle | undefined => {
  const { inquiryPurposeVehicle } = inquiry;
  if (!inquiryPurposeVehicle) return;
  const { purchasePrice, quantity, newVehicle, vehicleType, vehicleSubType, vehicleTypeLabel, vehicleSubTypeLabel } = inquiryPurposeVehicle;
  if (purchasePrice == null || quantity == null || newVehicle == null || !vehicleType || !vehicleSubType) return;

  const vehicle: Vehicle = {
    purchasePriceNet: purchasePrice,
    quantity,
    isNew: newVehicle,
    vehicleType: {
      createdDateTime: DateTime.now(),
      id: -1,
      value: vehicleType,
      name: vehicleTypeLabel,
    },
    vehicleCategory: {
      createdDateTime: DateTime.now(),
      id: -1,
      value: vehicleSubType,
      name: vehicleSubTypeLabel,
    },
  };
  return vehicle;
}


//#endregion vehicle

//#region debt rescheduling
const mapInquiryPurposeDebtReschedulingToDebtRescheduling = (inquiry: IInquiryResponse): DebtRescheduling | undefined => {
  const { amount, description } = inquiry;
  if (amount == null || !description) return;
  const debtRescheduling: DebtRescheduling = {
    financedAmount: amount,
    purpose: description,
  }
  return debtRescheduling;
}
//#endregion debt rescheduling

//#region factoring
const mapInquiryPurposeFactoringToFactoring = (inquiry: IInquiryResponse): Factoring | undefined => {
  const { amount } = inquiry;
  if (amount == null) return;
  const factoring: Factoring = {
    financedAmount: amount,
  }
  return factoring;
}
//#endregion factoring

// #region liquidity
const mapInquiryPurposeLiquidityToLiquidity = (inquiry: IInquiryResponse): Liquidity | undefined => {
  const { amount } = inquiry;
  if (amount == null) return;
  const liquidity: Liquidity = {
    financedAmount: amount,
  }
  return liquidity;
}
// #endregion liquidity

// #region goods
const mapInquiryPurposeGoodsToGoods = (inquiry: IInquiryResponse): Goods | undefined => {
  const { amount, description } = inquiry;
  if (amount == null || !description) return;
  const goods: Goods = {
    financedAmount: amount,
    purpose: description,
  }
  return goods;
}
// #endregion goods

// #region share acquisition
const mapInquiryPurposeShareAcquisitionToShareAcquisition = (inquiry: IInquiryResponse): ShareAcquisition | undefined => {
  const { amount, description } = inquiry;
  if (amount == null || !description) return;
  const shareAcquisition: ShareAcquisition = {
    financedAmount: amount,
    purpose: description,
  }
  return shareAcquisition;
}
// #endregion share acquisition

// #region machine
const mapInquiryPurposeMachineToMachine = (inquiry: IInquiryResponse): Machine | undefined => {
  const { inquiryPurposeMachine } = inquiry;
  if (!inquiryPurposeMachine) return;
  const { machineType, machineTypeLabel, purchasePrice, newMachine, alreadyOrdered } = inquiryPurposeMachine;
  if (!machineType || purchasePrice == null || newMachine == null || alreadyOrdered == null) return;

  const machine: Machine = {
    category: {
      createdDateTime: DateTime.now(),
      id: -1,
      value: machineType,
      name: machineTypeLabel,
    },
    purchasePriceNet: purchasePrice,
    isNew: newMachine,
    isOrdered: alreadyOrdered,
  };
  return machine;
}
// #endregion machine
//#endregion map back-end data to front-end data
