export enum TagType {
  // #region Company api
  CompanyApi_CompanySearchResultDto = "CompanyApi_CompanySearchResultDto",
  CompanyApi_CompanyProfileSearchResultDto = "CompanyApi_CompanyProfileSearchResultDto",
  CompanyApi_MyRecentlyViewedCompanyProfilesResponse = "CompanyApi_MyRecentlyViewedCompanyProfilesResponse",
  CompanyApi_CompanyProfileDto = "CompanyApi_CompanyProfileDto",
  CompanyApi_CompanyProfileLastRatingResponse = "CompanyApi_CompanyProfileLastRatingResponse",

  // #region Lookup
  CompanyApi_AddressTypeResponse = "CompanyApi_AddressTypeResponse",
  CompanyApi_AnnualNetProfitClassResponse = "CompanyApi_AnnualNetProfitClassResponse",
  CompanyApi_CommunicationInformationTypeResponse = "CompanyApi_CommunicationInformationTypeResponse",
  CompanyApi_CompanyProfileStatusResponse = "CompanyApi_CompanyProfileStatusResponse",
  CompanyApi_CountryResponse = "CompanyApi_CountryResponse",
  CompanyApi_EconomicSectorResponse = "CompanyApi_EconomicSectorResponse",
  CompanyApi_ExternalIdTypeResponse = "CompanyApi_ExternalIdTypeResponse",
  CompanyApi_LegalFormResponse = "CompanyApi_LegalFormResponse",
  CompanyApi_RatingAgencyResponse = "CompanyApi_RatingAgencyResponse",
  CompanyApi_RatingReportTypeResponse = "CompanyApi_RatingReportTypeResponse",
  CompanyApi_RegisterCourtResponse = "CompanyApi_RegisterCourtResponse",
  CompanyApi_RegisterTypeResponse = "CompanyApi_RegisterTypeResponse",
  CompanyApi_SalutationResponse = "CompanyApi_SalutationResponse",
  CompanyApi_TurnoverClassResponse = "CompanyApi_TurnoverClassResponse",
  CompanyApi_CountryOfOriginResponse = "CompanyApi_CountryOfOriginResponse",
  CompanyApi_ContactResponse = "CompanyApi_ContactResponse",
  CompanyApi_AddressResponse = "CompanyApi_AddressResponse",
  CompanyApi_BusinessConsentResponse = "CompanyApi_BusinessConsentResponse",
  CompanyApi_CreditRatingDisplayResponse = "CompanyApi_CreditRatingDisplayResponse",
  CompanyApi_MyNotificationResponse = "CompanyApi_MyNotificationResponse",
  // #endregion Lookup

  // #endregion Companies api

  // #region Inquiry api

  InquiryApi_InquiryResponse = "InquiryApi_InquiryResponse",
  InquiryApi_InquiryCollateralResponse = "InquiryApi_InquiryCollateralResponse",
  InquiryApi_InquiryPurposeGoodResponse = "InquiryApi_InquiryPurposeGoodResponse",
  InquiryApi_InquiryPurposeMachineResponse = "InquiryApi_InquiryPurposeMachineResponse",
  InquiryApi_InquiryPurposeVehicleResponse = "InquiryApi_InquiryPurposeVehicleResponse",
  InquiryApi_InquiryOffersResponse = "InquiryApi_InquiryOffersResponse",

  // #region Lookup
  InquiryApi_LookupFactoringBillsRangeResponse = "InquiryApi_LookupFactoringBillsRangeResponse",
  InquiryApi_LookupLoanTypeResponse = "InquiryApi_LookupLoanTypeResponse",
  InquiryApi_LookupPurposeResponse = "InquiryApi_LookupPurposeResponse",
  InquiryApi_LookupRealEstateProjectTypeResponse = "InquiryApi_LookupRealEstateProjectTypeResponse",
  InquiryApi_LookupRealEstateTypeResponse = "InquiryApi_LookupRealEstateTypeResponse",
  InquiryApi_LookupRealEstateUsageTypeResponse = "InquiryApi_LookupRealEstateUsageTypeResponse",
  InquiryApi_LookupVehicleTypeResponse = "InquiryApi_LookupVehicleTypeResponse",
  InquiryApi_LookupVehicleSubTypeResponse = "InquiryApi_LookupVehicleSubTypeResponse",
  InquiryApi_LookupAmortisationTypeResponse = "InquiryApi_LookupAmortisationTypeResponse",
  InquiryApi_LookupInquiryStatusResponse = "InquiryApi_LookupInquiryStatusResponse",
  // #endregion Lookup

  // #region Inquiry api

  // #region Document api
  DocumentApi_CreateOfferReponse = "DocumentApi_CreateOfferReponse",
  DocumentApi_GetRequestedDocuments = "DocumentApi_GetRequestedDocuments",
  DocumentApi_CompanyProfileDocumentsResponse = "DocumentApi_CompanyProfileDocumentsResponse",
  DocumentApi_OfferDocumentsResponse = "DocumentApi_OfferDocumentsResponse",
  DocumentApi_InquiryDocumentsResponse = "DocumentApi_InquiryDocumentsResponse",
  DocumentApi_UploadCompanyProfileDocument = "DocumentApi_UploadCompanyProfileDocument",
  DocumentApi_UploadOfferDocument = "DocumentApi_UploadOfferDocument",
  DocumentApi_UploadInquiryDocument = "DocumentApi_UploadInquiryProfileDocument",

  // #region Document api

  // #region Product api

  ProductApi_PreCheckTransactionResponse = "ProductApi_PreCheckTransactionResponse",
  ProductApi_CalculationResponse = "ProductApi_CalculationResponse",
  ProductApi_AcceptCalculationResponse = "ProductApi_AcceptCalculationResponse",
  ProductApi_CalculationResultResponse = "ProductApi_CalculationResultResponse",
  ProductApi_CalculationsResponse = "ProductApi_CalculationsResponse",
  ProductApi_CalculationResultOfferStatusResponse = "ProductApi_CalculationResultOfferStatusResponse",

  // #region Product api
}
