import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { getRouteTypeHref } from "@/routes/constants";
import { RouteType } from "@/types";
import { RoutesExcludingWithoutTranslation } from "@/types/routes";

type SubMenuButtonProps = {
  type: RoutesExcludingWithoutTranslation;
  companyId: number;
  selected?: RouteType;
};

export const SubMenuButton = ({ type, companyId, selected }: SubMenuButtonProps): JSX.Element => {
  const { t: tCommon } = useTranslation();
  let route = getRouteTypeHref(companyId, type);

  if (type === RouteType.CompanyInformation) {
    route = `${route}?redirect=false`;
  }

  return (
    <Button
      variant="primaryButton"
      component={Link}
      to={route}
      sx={(theme) => ({
        position: "relative",
        top: "10px",
        left: "25px",
        border: `1px solid ${selected === type ? theme.palette.primary.main : "#eee"}`,
        background: selected === type ? "#f1f5f9" : "transparent",
        color: `${selected === type ? theme.palette.primary.main : "#888"}`,
      })}
    >
      {tCommon(`pages.${type}`)}
    </Button>
  );
};
